// packing_slip

import React, { useState } from 'react';
import { fetchDataFromApi } from '../../utils/api';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    CircularProgress,
    Paper,
} from '@mui/material';

const PackingShip = () => {
    const [waybill, setWaybill] = useState('');
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleTrackShipment = async () => {
        setLoading(true);
        setResult(null); // Reset the result before fetching new data
        try {
            const res = await fetchDataFromApi(`/api/delhivery/packing-slip/${waybill}`);
            setResult(res);
        } catch (err) {
            console.error(err);
            setResult({ error: 'Failed to fetch shipment details.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: 4 }}>
            <Paper elevation={3} sx={{ padding: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Packing Slip
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Way Bill Number"
                        variant="outlined"
                        value={waybill}
                        onChange={(e) => setWaybill(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTrackShipment}
                        disabled={loading || !waybill.trim()}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Track Shipment'}
                    </Button>
                </Box>
                {result && (
                    <Box sx={{ marginTop: 4 }}>
                        <Typography variant="h6" component="h2">
                            Result:
                        </Typography>
                        <Paper
                            variant="outlined"
                            sx={{
                                padding: 2,
                                marginTop: 2,
                                backgroundColor: '#f9f9f9',
                                maxHeight: 300,
                                overflow: 'auto',
                            }}
                        >
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                {JSON.stringify(result, null, 2)}
                            </pre>
                        </Paper>
                    </Box>
                )}
            </Paper>
        </Container>
    );
};

export default PackingShip;
