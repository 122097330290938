import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Typography,
  Rating,
  Box,
  Grid,
  Card,
  CardMedia,
  Chip,
  Tooltip,
} from "@mui/material";
import { MdClose, MdOutlineCompareArrows } from "react-icons/md";

import { FaRegHeart } from "react-icons/fa6";
import { useSnackbar } from "../../context/SnackbarProvider";
import { LuShoppingCart } from "react-icons/lu";
import { FaHeart } from "react-icons/fa";

import QuantityBox from "../QuantityBox";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useEffect, useState, useRef } from "react";
import { fetchDataFromApi, postData } from "../../utils/api";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const ProductModal = ({ data, isOpenProductModal, setisOpenProductModal }) => {
  const [productQuantity, setProductQuantity] = useState(1);
  const [activeSize, setActiveSize] = useState(null);
  const [isAddedToMyList, setIsAddedToMyList] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [addingInCart, setAddingInCart] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const zoomSliderBig = useRef();
  const zoomSlider = useRef();

  const goto = (index) => {
    setSlideIndex(index);
    zoomSlider.current.swiper.slideTo(index);
    zoomSliderBig.current.swiper.slideTo(index);
  };

  useEffect(() => {
    if (data?.size?.length === 0) {
      setActiveSize(1);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(
      `/api/my-list?productId=${data?.id}&userId=${user?.userId}`
    )
      .then((res) => setIsAddedToMyList(res.length !== 0))
      .catch((error) =>
        showSnackbar(error.message || "Internal server error!", "error")
      );

    fetchDataFromApi(`/api/my-list?productId=${data?.id}&userId=${user?.userId}`).then((res) => {
      if (res.length !== 0) {
        setIsAddedToMyList(true);
      }
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  }, [data]);

  const handleQuantityChange = (val) => setProductQuantity(val);
  const handleSizeSelection = (index) => setActiveSize(index);

  const addToCart = () => {

    if (activeSize !== null) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        const cartItem = {
          // @@@@@@@@@@@@@@@@    ideal fields to item in a cart @@@@@@@@@@@@@@@@@@@@@@@@
            // cartFields.productTitle = productData?.name;
      // cartFields.image = productData?.images[0];
      // cartFields.rating = productData?.rating;
      // cartFields.price = productData?.price;
      // cartFields.quantity = productQuantity;
      // cartFields.subTotal = parseInt(productData?.price * productQuantity);
      // cartFields.productId = productData?.id;
      // cartFields.countInStock = productData?.countInStock;
      // cartFields.userId = user?.userId;
          productTitle: data?.name,
          image: data?.images[0],
          rating: data?.rating,
          price: data?.price,
          quantity: productQuantity,
          subTotal: data?.price * productQuantity,
          productId: data?.id,
          userId: user?.userId,
          countInStock: data?.countInStock
        };

        setAddingInCart(true);
        postData(`/api/cart/add`, cartItem)
          .then(() => showSnackbar("Item added to cart", "success", "#aadbaa"))
          .finally(() => setAddingInCart(false));
      } else {
        showSnackbar("Please log in first", "error", "#f1b9b9");
      }
    }
  };

  const addToWishlist = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
    
      const wishlistItem = {
        productTitle: data?.name,
        image: data?.images[0],
        rating: data?.rating,
        price: data?.price,
        productId: data?.id,
        userId: user?.userId,
       
      };

      postData(`/api/my-list/add/`, wishlistItem).then((res) => {
        if (res.status !== false) {
          showSnackbar("The product added in my list", "success", "#aadbaa");

          const user = JSON.parse(localStorage.getItem("user"));

          fetchDataFromApi(`/api/my-list?productId=${data?.id}&userId=${user?.userId}`).then((res) => {
            if (res.length !== 0) {
              setIsAddedToMyList(true);
            }
          }).catch((error) => {
            const msg = error || "Internal srever error!!"
            // showSnackbar(msg, "error", "#f1b9b9");
          });
        } else {
          showSnackbar(res.msg, "error", "#f1b9b9");
        }
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    } else {
      showSnackbar("Please log in to continue", "error", "#f1b9b9");
    }
  };

  return (
    <Dialog
      open={isOpenProductModal}
      onClose={() => setisOpenProductModal(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{data?.name}</Typography>
        <IconButton onClick={() => setisOpenProductModal(false)}>
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <div className="productZoom">
              <div className="position-relative mb-3">
                <div
                  className="badge"
                  style={{
                    background: "var(--secondaryColor)",
                    color: "var(--textColor)",
                    border: "1px solid var(--primaryColor)",
                  }}
                >
                  {data?.discount}%
                </div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  navigation={false}
                  modules={[Navigation]}
                  className="zoomSliderBig"
                  ref={zoomSliderBig}
                >
                  {data?.images?.map((img, index) => (
                    <SwiperSlide key={index}>
                      <div className="item">
                        <InnerImageZoom
                          zoomType="hover"
                          zoomScale={1}
                          src={img}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <Swiper
                slidesPerView={3}
                ref={zoomSlider}
                spaceBetween={5}
                navigation={false}
                loop={false}
                centeredSlides={false}
                className="mb-5"
                breakpoints={{
                  200: { slidesPerView: 2 },
                  350: { slidesPerView: 3 },
                  460: { slidesPerView: 4 },
                  576: { slidesPerView: 5 },
                  768: { slidesPerView: 3 },
                }}
              >
                {data?.images?.map((img, index) => (
                  <SwiperSlide key={`thumb-${index}`}>
                    <Card
                      onClick={() => goto(index)}
                      sx={{
                        boxShadow: slideIndex === index ? 3 : 0,
                        border:
                          slideIndex === index
                            ? "1.5px solid var(--primaryColor)"
                            : "1px solid rgba(0, 0, 0, 0.45)",
                        width: "70px",
                        height: "70px",
                        margin: "0.5rem 0.35rem",
                        cursor: "pointer",
                        borderRadius: 2,
                        opacity: slideIndex === index ? "1" : "0.45",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={img}
                        alt={`Product Image ${index}`}
                        sx={{
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="body1" mr={1}>
                Brand:
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {data?.brand}
              </Typography>
            </Box>
            <Rating
              name="read-only"
              value={parseInt(data?.rating)}
              readOnly
              size="small"
              precision={0.5}
            />

            <Box display="flex" alignItems="center" mt={2}>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ textDecoration: "line-through" }}
              >
                Rs: {data?.oldPrice}
              </Typography>
              <Typography variant="h6" color="error" ml={2}>
                Rs: {data?.price}
              </Typography>
            </Box>

            {data?.countInStock >= 1 ? (
              <Chip
                label="IN STOCK"
                sx={{
                  background: "#e5f8ed",
                  color: "green",
                  fontWeight: "500",
                  margin: "1.5rem 0",
                }}
              />
            ) : (
              <Chip
                label="OUT OF STOCK"
                sx={{
                  background: "#f8e5e5",
                  color: "red",
                  fontWeight: "500",
                  margin: "1.5rem 0",
                }}
              />
            )}

            <Typography variant="body2" paragraph sx={{ textAlign: "justify" }}>
              {data?.description}
            </Typography>

            {data?.size?.length > 0 && (
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Size:</Typography>
                <Box display="flex" ml={2}>
                  {data.size.map((size, index) => (
                    <Button
                      key={index}
                      variant={activeSize === index ? "contained" : "outlined"}
                      color="primary"
                      size="small"
                      onClick={() => handleSizeSelection(index)}
                      sx={{ mx: 0.5 }}
                    >
                      {size}
                    </Button>
                  ))}
                </Box>
              </Box>
            )}

            {data?.productWeight?.length > 0 && (
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Weight:</Typography>
                <Box display="flex" ml={2}>
                  {data.productWeight.map((weight, index) => (
                    <Button
                      key={index}
                      variant={activeSize === index ? "contained" : "outlined"}
                      color="primary"
                      size="small"
                      onClick={() => handleSizeSelection(index)}
                      sx={{ mx: 0.5 }}
                    >
                      {weight}
                    </Button>
                  ))}
                </Box>
              </Box>
            )}

            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="1rem"
              my={3}
            >
              <QuantityBox quantity={handleQuantityChange} item={data} />
              <Button
                variant="contained"
                color="primary"
                startIcon={<LuShoppingCart style={{ color: "white" }} />}
                onClick={addToCart}
                disabled={addingInCart}
                sx={{ minWidth: "145px" }}
                size="medium"
              >
                {addingInCart ? "Adding..." : "Add to Cart"}
              </Button>
            </Box>

            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="1rem"
              mt={3}
            >
              <Tooltip
                title={`${isAddedToMyList ? "Added to Wishlist" : "Add to Wishlist"
                  }`}
                placement="top"
                disableInteractive
              >
                <Button
                  startIcon={
                    isAddedToMyList ? (
                      <FaHeart color="red" background="red" />
                    ) : (
                      <FaRegHeart color="var(--textColor)" />
                    )
                  }
                  onClick={addToWishlist}
                  sx={{
                    color: isAddedToMyList ? "red" : "var(--textColor)",
                  }}
                >
                  {isAddedToMyList ? "Added to Wishlist" : "Add to Wishlist"}
                </Button>
              </Tooltip>

              <Tooltip
                title="Add to Compare"
                placement="top"
                disableInteractive
              >
                <Button
                  startIcon={
                    <MdOutlineCompareArrows color="var(--textColor)" />
                  }
                  sx={{ ml: 2, color: "var(--textColor)" }}
                >
                  Compare
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProductModal;
