import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { useSnackbar } from "../../../../../context/SnackbarProvider";
import { IoCloseSharp } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaRegImages } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


import {
  deleteImages,
  postData,
  uploadImage,
} from "../../../../../utils/api";

const CreatePrivacyPolicyPage = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  // Form states
  const [title, setTitle] = useState('');
  const [version, setVersion] = useState('');
  const [slug, setSlug] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [sections, setSections] = useState([
    { title: '', description: '', points: [{ title: '', description: '' }] },
  ]);
  const [providerName, setProviderName] = useState('');
  const [providerWebsite, setProviderWebsite] = useState('');
  const [providerLogo, setProviderLogo] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Handling Keywords
  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      setMetaKeywords([...metaKeywords, newKeyword.trim()]);
      setNewKeyword('');
    }
  };

  const handleRemoveKeyword = (index) => {
    setMetaKeywords((prev) => prev.filter((_, i) => i !== index));
  };

  // Handling Sections
  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index] = { ...updatedSections[index], [field]: value };
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    setSections([
      ...sections,
      { title: '', description: '', points: [{ title: '', description: '' }] },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSections((prev) => prev.filter((_, i) => i !== index));
  };

  // Handling Points within Sections
  const handlePointChange = (sectionIndex, pointIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points[pointIndex] = {
      ...updatedSections[sectionIndex].points[pointIndex],
      [field]: value,
    };
    setSections(updatedSections);
  };

  const handleAddPoint = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points.push({ title: '', description: '' });
    setSections(updatedSections);
  };

  const handleRemovePoint = (sectionIndex, pointIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points = updatedSections[sectionIndex].points.filter((_, i) => i !== pointIndex);
    setSections(updatedSections);
  };

  const onChangeFile = async (e, apiEndPoint) => {
    const formdata = new FormData();
    try {
      const file = e.target.files ? e.target.files[0] : null;
      setUploading(true);

      if (
        file &&
        (file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/webp")
      ) {
        formdata.append('images', file);

      } else {
        showSnackbar("Please select a valid JPG or PNG image file.", "error", "#f1b9b9");
        return false;
      }
    } catch (error) {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    }

    uploadImage(apiEndPoint, formdata).then((res) => {
      setProviderLogo(res)
      setUploading(false);
      showSnackbar("Avatar Updated", "success", "#aadbaa");;
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      title,
      version,
      slug,
      metaData: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
      },
      sections,
      provider: {
        name: providerName,
        website: providerWebsite,
        logo: providerLogo,
      },
      description,
    };

    try {
      postData("/api/privacy-policy/create", payload).then((res) => {
        showSnackbar("Successfully added privacy policy", "success", "#aadbaa");
        navigate('/admin/legal/privacy-policy')
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    } catch (error) {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
      // showSnackbar("An error accoured!!", "error", "#f1b9b9");
    } finally {
      setLoading(false);
    }
  };

  const removeImg = async (imgUrl) => {
    deleteImages(`/api/privacy-policy/deleteImage?img=${imgUrl}`).then((res) => {
      setProviderLogo(null)
      showSnackbar("Image Deleted!", "success", "#aadbaa");
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: '1rem',
          fontWeight: '600',
          color: 'var(--textHeading)',
          marginBottom: '2rem',
        }}
      >
        Dashboard / Legal / Create Privacy Policy
      </Typography>
      <Card
        sx={{
          boxShadow: 2,
          borderRadius: '0.5rem',
          border: '1px solid var(--borderCool)',
          maxWidth: '1200px',
          background: 'var(--bgLight)',
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Title, Version, Slug */}
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Version"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider
                  sx={{
                    height: '2px',
                    background: 'var(--borderCool)',
                    opacity: '0.5',
                  }}
                />
              </Grid>

              {/* Meta Data */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                  Meta Data
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Meta Title"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Meta Description"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  multiline
                  rows={3}
                  fullWidth
                  required
                />
              </Grid>

              {/* Keywords */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                  Meta Keywords
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'var(--bgColor)',
                  }}
                >
                  <TextField
                    label="Add Keyword"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={handleAddKeyword}
                    startIcon={<AddIcon />}
                    sx={{ mt: 1, width: '220px' }}
                  >
                    Add Keyword
                  </Button>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                {metaKeywords.map((keyword, index) => (
                  <Paper key={index} variant="outlined" sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <Typography>{keyword}</Typography>
                    <IconButton onClick={() => handleRemoveKeyword(index)} color="error">
                      <MdOutlineDeleteOutline />
                    </IconButton>
                  </Paper>
                ))}
              </Grid>

              {/* Provider Info */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                  Provider Information
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Provider Name"
                  value={providerName}
                  onChange={(e) => setProviderName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Provider Website"
                  value={providerWebsite}
                  onChange={(e) => setProviderWebsite(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="imgUploadBox d-flex align-items-center">
                  {
                    providerLogo &&
                    <div className="uploadBox">
                      <span
                        className="remove"
                        onClick={() => removeImg(providerLogo)}
                      >
                        <IoCloseSharp />
                      </span>
                      <div className="box">
                        <LazyLoadImage
                          alt={"image"}
                          effect="blur"
                          className="w-100"
                          src={providerLogo}
                        />
                      </div>
                    </div>
                  }
                  {
                    !providerLogo && (
                      <div className="uploadBox">
                        {uploading === true ? (
                          <div className="progressBar text-center d-flex align-items-center justify-content-center flex-column">
                            <CircularProgress />
                            <span>Uploading...</span>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              multiple
                              onChange={(e) =>
                                onChangeFile(e, "/api/images/upload")
                              }
                              name="images"
                            />
                            <div className="info">
                              <FaRegImages />
                              <h5>image upload</h5>
                            </div>
                          </>
                        )}
                      </div>
                    )
                  }
                </div>
              </Grid>

              {/* Sections */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                  Sections
                </Typography>
              </Grid>

              {sections.map((section, sectionIndex) => (
                <Grid item xs={12} key={sectionIndex}>
                  <Paper
                    variant="outlined"
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      background: 'var(--bgColor)',
                      mb: 2,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Section Title"
                          value={section.title}
                          onChange={(e) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Section Description"
                          value={section.description}
                          onChange={(e) => handleSectionChange(sectionIndex, 'description', e.target.value)}
                          multiline
                          rows={2}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ fontSize: '1rem', mt: 2 }}>
                        Points
                      </Typography>
                    </Grid>

                    {section.points.map((point, pointIndex) => (
                      <Grid container spacing={2} key={pointIndex}>
                        <Grid item xs={12} md={5}>
                          <TextField
                            label="Point Title"
                            value={point.title}
                            onChange={(e) => handlePointChange(sectionIndex, pointIndex, 'title', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            label="Point Description"
                            value={point.description}
                            onChange={(e) => handlePointChange(sectionIndex, pointIndex, 'description', e.target.value)}
                            multiline
                            rows={1}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <IconButton onClick={() => handleRemovePoint(sectionIndex, pointIndex)} color="error">
                            <MdOutlineDeleteOutline />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    <Button
                      variant="outlined"
                      onClick={() => handleAddPoint(sectionIndex)}
                      startIcon={<AddIcon />}
                      sx={{ mt: 2 }}
                    >
                      Add Point
                    </Button>
                    <IconButton onClick={() => handleRemoveSection(sectionIndex)} color="error" sx={{ mt: 1 }}>
                      <MdOutlineDeleteOutline />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={handleAddSection}
                  startIcon={<AddIcon />}
                  sx={{ mt: 2 }}
                >
                  Add Section
                </Button>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ mt: 2 }}>
                  {loading ? <CircularProgress size={24} /> : 'Create Privacy Policy'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreatePrivacyPolicyPage;
