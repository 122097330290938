import ProductZoom from "../../Components/ProductZoom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import Button from "@mui/material/Button";
import { BsCartFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineCompareArrows } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import RelatedProducts from "./RelatedProducts";
import { useSnackbar } from "../../context/SnackbarProvider";
import { useParams } from "react-router-dom";
import { fetchDataFromApi, postData } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { FaHeart } from "react-icons/fa";
import { Chip } from "@mui/material";

import "./style.css";

const ProductDetails = () => {
  const [activeSize, setActiveSize] = useState(null);
  const [activeTabs, setActiveTabs] = useState(0);
  const [productData, setProductData] = useState([]);
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsData, setreviewsData] = useState([]);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);
  let [cartFields, setCartFields] = useState({});
  let [productQuantity, setProductQuantity] = useState();
  const [tabError, setTabError] = useState(false);
  const [addingInCart, setAddingInCart] = useState(false);
  const [cartData, setCartData] = useState();
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveSize(null);
    fetchDataFromApi(`/api/products/${id}`)
      .then((res) => {
        setProductData(res);
          console.log(res);
        if (
          res?.productRam?.length === 0 &&
          res?.productWeight.length === 0 &&
          res?.size.length === 0
        ) {
          setActiveSize(1);
        }

        fetchDataFromApi(`/api/products?subCatId=${res?.subCatId}`)
          .then((res) => {
            const filteredData = res?.products?.filter(
              (item) => item.id !== id
            );
            setRelatedProductData(filteredData);
          })
          .catch((error) => {
            const msg = error.message || "Internal srever error!!";
            // showSnackbar(msg, "error", "#f1b9b9");
          });
      })
      .catch((error) => {
        const msg = error.message || "Internal srever error!!";

      });

    fetchDataFromApi(`/api/productReviews?productId=${id}`)
      .then((res) => {
        setreviewsData(res);
      })
      .catch((error) => {
        const msg = error.message || "Internal srever error!!";
        // showSnackbar(msg, "error", "#f1b9b9");
      });

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`)
      .then((res) => {
        if (res.length !== 0) {
          setSsAddedToMyList(true);
        }
      })
      .catch((error) => {
        const msg = error.message || "Internal srever error!!";
        // showSnackbar(msg, "error", "#f1b9b9");
      });
  }, [id]);

  const [rating, setRating] = useState(1);
  const [reviews, setReviews] = useState({
    productId: "",
    customerName: "",
    customerId: "",
    review: "",
    customerRating: 0,
  });

  const onChangeInput = (e) => {
    setReviews(() => ({
      ...reviews,
      [e.target.name]: e.target.value,
    }));
  };

  const changeRating = (e) => {
    setRating(e.target.value);
    reviews.customerRating = e.target.value;
  };

  const addReview = (e) => {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));

    reviews.customerName = user?.name;
    reviews.customerId = user?.userId;
    reviews.productId = id;

    setIsLoading(true);

    postData("/api/productReviews/add", reviews)
      .then((res) => {
        setIsLoading(false);

        reviews.customerRating = 1;

        setReviews({
          review: "",
          customerRating: 1,
        });

        fetchDataFromApi(`/api/productReviews?productId=${id}`)
          .then((res) => {
            setreviewsData(res);
          })
          .catch((error) => {
            const msg = error.message || "Internal srever error!!";
            // showSnackbar(msg, "error", "#f1b9b9");
          });
      })
      .catch((error) => {
        const msg = error.message || "Internal srever error!!";
        // showSnackbar(msg, "error", "#f1b9b9");
      });
  };

  const quantity = (val) => {
    setProductQuantity(val);
  };

  const addToCartData = (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    if (user && token) {
      setAddingInCart(true);
      console.log("addto cart")
      postData(`/api/cart/add`, data)
        .then((res) => {
          if (res.status !== false) {
            showSnackbar("Item is added in the cart", "success", "#aadbaa");
            setTimeout(() => {
              setAddingInCart(false);
            }, 1000);

            fetchDataFromApi(`/api/cart?userId=${user?.userId}`)
              .then((res) => {
                setCartData(res);
              })
              .catch((error) => {
                const msg = error.message || "Internal srever error!!";
                // showSnackbar(msg, "error", "#f1b9b9");
              });
          } else {
            showSnackbar(res.msg, "error", "#f1b9b9");
            setAddingInCart(false);
          }
        })
        .catch((error) => {
          const msg = error.message || "Internal srever error!!";
          // showSnackbar(msg, "error", "#f1b9b9");
        });
    } else {
      showSnackbar("Please login first", "error", "#f1b9b9");
    }
  };

  const addtoCart = () => {
    if (activeSize !== null) {
      console.log("here i ma")
      const user = JSON.parse(localStorage.getItem("user"));
      
      cartFields.productTitle = productData?.name;
      cartFields.image = productData?.images[0];
      cartFields.rating = productData?.rating;
      cartFields.price = productData?.price;
      cartFields.quantity = productQuantity;
      cartFields.subTotal = parseInt(productData?.price * productQuantity);
      cartFields.productId = productData?.id;
      cartFields.countInStock = productData?.countInStock;
      cartFields.userId = user?.userId;

      addToCartData(cartFields);
    } else {
      console.log("here i ma err") 
      setTabError(true);
    }
  };

  const selectedItem = () => { };

  const gotoReviews = () => {
    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });

    setActiveTabs(2);
  };

  const addToMyList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined && user !== null && user !== "") {
      const data = {
        productTitle: productData?.name,
        image: productData?.images[0],
        rating: productData?.rating,
        price: productData?.price,
        productId: id,
        userId: user?.userId,
      };
      postData(`/api/my-list/add/`, data)
        .then((res) => {
          if (res.status !== false) {
            showSnackbar("The product added in my list", "success", "#aadbaa");

            fetchDataFromApi(
              `/api/my-list?productId=${id}&userId=${user?.userId}`
            )
              .then((res) => {
                if (res.length !== 0) {
                  setSsAddedToMyList(true);
                }
              })
              .catch((error) => {
                const msg = error.message || "Internal srever error!!";
                // showSnackbar(msg, "error", "#f1b9b9");
              });
          } else {
            const msg = res.message || "Something went wrong!!";
            // showSnackbar(msg, "error", "#f1b9b9");
          }
        })
        .catch((error) => {
          const msg = error.message || "Internal srever error!!";
          // showSnackbar(msg, "error", "#f1b9b9");
        });
    } else {
      showSnackbar("Please Login to continue", "error", "#f1b9b9");
    }
  };

  return (
    <>
      <section className="productDetails section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <ProductZoom
                images={productData?.images}
                discount={productData?.discount}
              />
            </div>

            <div className="col-12 col-md-7 part2">
              <h2 className="hd text-capitalize">{productData?.name}</h2>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "1rem",
                  margin: "1.5rem 0",
                }}
              >
                <li className="list-inline-item">
                  <div className="d-flex align-items-center ">
                    <span className="text-light mr-2">Brands :</span>
                    <span>{productData?.brand}</span>
                  </div>
                </li>

                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <Rating
                      name="read-only"
                      value={parseInt(productData?.rating)}
                      precision={0.5}
                      readOnly
                      size="small"
                    />

                    <span
                      className="text-light cursor ml-2"
                      onClick={gotoReviews}
                    >
                      {reviewsData?.length} Review
                    </span>
                  </div>
                </li>
              </ul>

              <div className="d-flex info mb-3">
                <span className="oldPrice">Rs: {productData?.oldPrice}</span>
                <span className="netPrice text-danger ml-2">
                  Rs: {productData?.price}
                </span>
              </div>

              {productData?.countInStock >= 1 ? productData.countInStock > 5 ? (
                <Chip
                  label="IN STOCK"
                  sx={{
                    background: "#e5f8ed",
                    color: "green",
                    fontWeight: "500",
                  }}
                />
              ) :
              (
              <Chip
                  label={`Only ${productData.countInStock} item left !`}
                  sx={{
                    background: "#f8e5e5",
                    color: "red",
                    fontWeight: "500",
                  }}
                />
              )
              
              : (
                <Chip
                  label="OUT OF STOCK"
                  sx={{
                    background: "#f8e5e5",
                    color: "red",
                    fontWeight: "500",
                  }}
                />
              )}

              <p className="mt-4 mb-4" style={{ textAlign: "justify" }}>
                {productData?.description}
              </p>

              {productData?.size?.length !== 0 && (
                <div className="productSizeAndWeight d-flex align-items-center">
                  <span>Size:</span>
                  <ul
                    className={`list-inline mb-0 pl-3 ${tabError === true && "error"
                      }`}
                  >
                    {productData?.size?.map((item, index) => {
                      return (
                        <li className="list-inline-item">
                          <a
                            className={`tag ${activeSize === index ? "active" : ""
                              }`}
                            onClick={() => isActive(index)}
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {productData?.productWeight?.length !== 0 && (
                <div className="productSizeAndWeight d-flex align-items-center">
                  <span>Weight:</span>
                  <ul
                    className={`list list-inline mb-0 pl-3 ${tabError === true && "error"
                      }`}
                  >
                    {productData?.productWeight?.map((item, index) => {
                      return (
                        <li className="list-inline-item">
                          <a
                            className={`tag ${activeSize === index ? "active" : ""
                              }`}
                            onClick={() => isActive(index)}
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div className="actions_">
                <QuantityBox
                  quantity={quantity}
                  item={productData}
                  selectedItem={selectedItem}
                />

                <div className="d-flex align-items-center btnActions">
                  {/* Add to cart Button */}
                  <Button
                    startIcon={<BsCartFill style={{ color: "#fff" }} />}
                    variant="contained"
                    sx={{ borderRadius: "50px" }}
                    size="large"
                    onClick={() => addtoCart()}
                  >
                    {addingInCart === true ? "adding..." : " Add to cart"}
                  </Button>

                  {/* Wishlist Button */}
                  <Tooltip
                    title={`${isAddedToMyList === true
                      ? "Added to Wishlist"
                      : "Add to Wishlist"
                      }`}
                    placement="top"
                  >
                    <Button
                      className={`btn-blue btn-lg btn-big btn-circle ml-4`}
                      onClick={() => addToMyList(id)}
                    >
                      {isAddedToMyList === true ? (
                        <FaHeart className="text-danger" />
                      ) : (
                        <FaRegHeart />
                      )}
                    </Button>
                  </Tooltip>

                  {/* Compare Button */}
                  <Tooltip title="Add to Compare" placement="top">
                    <Button className="btn-lg btn-big btn-circle ml-3">
                      <MdOutlineCompareArrows />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <br />

          <div className="card mt-5 detailsPageTabs">
            <div className="customTabs">
              <ul className="list list-inline">
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 0 && "active"}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 1 && "active"}`}
                    onClick={() => {
                      setActiveTabs(1);
                    }}
                  >
                    Additional info
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 2 && "active"}`}
                    onClick={() => {
                      setActiveTabs(2);
                    }}
                  >
                    Reviews ({reviewsData?.length})
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div className="tabContent">{productData?.description}</div>
              )}

              {activeTabs === 1 && (
                <div className="tabContent">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr className="frame">
                          <th>Body</th>
                          <td>
                            <p>Plastic</p>
                          </td>
                        </tr>
                        <tr className="weight-wo-wheels">
                          <th>Weight</th>
                          <td>
                            <p>500ml</p>
                          </td>
                        </tr>
                        <tr className="width">
                          <th>Size</th>
                          <td>
                            <p>10cm x 10 cm x 15cm</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {activeTabs === 2 && (
                <div className="tabContent">
                  <div className="row">
                    <div className="col-md-12">
                      {reviewsData.length > 0 && (
                        <>
                          <h3>Customer questions & answers</h3>
                          <br />
                        </>
                      )}

                      {reviewsData?.length !== 0 &&
                        reviewsData
                          ?.slice(0)
                          ?.reverse()
                          ?.map((item, index) => {
                            return (
                              <div
                                className="reviewBox mb-4 border-bottom"
                                key={index}
                              >
                                <div className="info">
                                  <div className="d-flex align-items-center w-100">
                                    <h5>{item?.customerName}</h5>

                                    <div className="ml-auto">
                                      <Rating
                                        name="half-rating-read"
                                        value={item?.customerRating}
                                        readOnly
                                        size="small"
                                      />
                                    </div>
                                  </div>

                                  <h6 className="text-light">
                                    {item?.dateCreated}
                                  </h6>

                                  <p>{item?.review} </p>
                                </div>
                              </div>
                            );
                          })}

                      <br className="res-hide" />

                      <form className="reviewForm" onSubmit={addReview}>
                        <h4>Add a review</h4>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Write a Review"
                            name="review"
                            value={reviews.review}
                            onChange={onChangeInput}
                          ></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <Rating
                                name="rating"
                                value={rating}
                                precision={0.5}
                                onChange={changeRating}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-blue btn-lg btn-big btn-round"
                          >
                            {isLoading === true ? (
                              <CircularProgress
                                color="inherit"
                                className="loader"
                              />
                            ) : (
                              "Submit Review"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />

          {relatedProductData?.length !== 0 && (
            <RelatedProducts
              title="RELATED PRODUCTS"
              data={relatedProductData}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
