import React, { useState, useContext } from 'react';
import { fetchDataFromApi } from '../../utils/api';
import { MyContext } from "../../App";

const BulkWayBill = () => {
    const [count, setCount] = useState('');
    const [result, setResult] = useState(null);
    const context = useContext(MyContext);

    const handleCheckServiceability = async () => {
        await fetchDataFromApi(`/api/delhivery/bulk/fetch-waybill/${count}`)
            .then((res) => {
                setResult(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <h2>Fetch Bulk Way Bill</h2>
            <input
                type="Number"
                placeholder="Enter count"
                value={count}
                onChange={(e) => setCount(e.target.value)}
            />
            <button onClick={handleCheckServiceability}>Check</button>
            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
        </div>
    );
};

export default BulkWayBill;
