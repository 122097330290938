import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import Button from "@mui/material/Button";
import { useContext, useEffect, useState } from "react";
import { useSnackbar } from "../../context/SnackbarProvider";
import "./style.css";

const QuantityBox = (props) => {
  const [inputVal, setInputVal] = useState(1);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      props?.value !== undefined &&
      props?.value !== null &&
      props?.value !== ""
    ) {
      setInputVal(parseInt(props?.value));
    }
  }, [props.value]);

  const minus = () => {
    if (inputVal !== 1 && inputVal > 0) {
      setInputVal(inputVal - 1);
    }
  };

  const plus = () => {
    let stock = parseInt(props.item.countInStock);
    if (inputVal < stock) {
      setInputVal(inputVal + 1);
    } else {
      showSnackbar("The quantity is greater than product count in stock", "error", "#f1b9b9");
    }
  };

  useEffect(() => {
    if (props.quantity) {
      props.quantity(inputVal);
    }

    if (props.selectedItem) {
      props.selectedItem(props.item, inputVal);
    }
  }, [inputVal]);

  return (
    <div className="quantityDrop d-flex align-items-center">
      <Button onClick={minus}>
        <FaMinus />
      </Button>
      <input type="text" value={inputVal} />
      <Button onClick={plus}>
        <FaPlus />
      </Button>
    </div>
  );
};

export default QuantityBox;
