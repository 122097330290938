import React, { useState } from 'react';
import axios from 'axios';

const UpdateShipment = () => {
    const [shipmentDetails, setShipmentDetails] = useState({
        shipmentId: '',
        newDeliveryLocation: '',
        newWeight: '',
        newCodAmount: '',
    });
    const [result, setResult] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails({ ...shipmentDetails, [name]: value });
    };

    const handleUpdateShipment = async () => {
        try {
            const response = await axios.post('/api/delhivery/update-shipment', { shipmentDetails });
            setResult(response.data);
        } catch (error) {
            console.error('Error updating shipment:', error);
        }
    };

    return (
        <div>
            <h2>Update Shipment</h2>
            <input
                type="text"
                name="shipmentId"
                placeholder="Shipment ID"
                value={shipmentDetails.shipmentId}
                onChange={handleInputChange}
            />
            <input
                type="text"
                name="newDeliveryLocation"
                placeholder="New Delivery Location"
                value={shipmentDetails.newDeliveryLocation}
                onChange={handleInputChange}
            />
            <input
                type="number"
                name="newWeight"
                placeholder="New Weight (kg)"
                value={shipmentDetails.newWeight}
                onChange={handleInputChange}
            />
            <input
                type="number"
                name="newCodAmount"
                placeholder="New COD Amount"
                value={shipmentDetails.newCodAmount}
                onChange={handleInputChange}
            />
            <button onClick={handleUpdateShipment}>Update Shipment</button>
            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
        </div>
    );
};

export default UpdateShipment;
