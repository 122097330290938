import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import "../style.css";
import { IoCaretDownCircleOutline } from "react-icons/io5";

const Navigation = (props) => {
  const [isopenSidebarVal, setisopenSidebarVal] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsOpenNav(props.isOpenNav);
  }, [props.isOpenNav]);

  return (
    <nav>
      <div className="container">
        <div className="row">
          <div className="col-sm-2 navPart1 ">
            <div className="catWrapper">
              <Button
                className="allCatTab align-items-center res-hide"
                variant="outlined"
                endIcon={
                  <IoCaretDownCircleOutline color="var(--primaryColor)" />
                }
                sx={{
                  fontWeight: "500",
                  borderWidth: "1.5px",
                  "&:hover": {
                    borderWidth: "1.5px",
                  },
                }}
                onClick={() => setisopenSidebarVal(!isopenSidebarVal)}
              >
                Select Category
              </Button>

              <div
                className={`sidebarNav ${
                  isopenSidebarVal === true ? "open" : ""
                }`}
              >
                <ul>
                  {props.navData?.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/products/category/${item?._id}`}
                          className={
                            location.pathname ===
                            `/products/category/${item?._id}`
                              ? "active"
                              : ""
                          }
                        >
                          <Button>
                            {item?.name} <FaAngleRight className="ml-auto" />
                          </Button>
                        </Link>
                        {item?.children?.length !== 0 && (
                          <div className="submenu">
                            {item?.children?.map((subCat, key) => {
                              return (
                                <Link
                                  to={`/products/subCat/${subCat?._id}`}
                                  key={key}
                                  className={
                                    location.pathname ===
                                    `/products/subCat/${subCat?._id}`
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <Button>{subCat?.name}</Button>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`col-sm-10 navPart2 d-flex align-items-center res-nav-wrapper ${
              isOpenNav === true ? "open" : "close"
            }`}
          >
            <div className="res-nav-overlay" onClick={props.closeNav}></div>

            <ul className="list list-inline ml-auto res-nav">
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link
                  to="/"
                  className={location.pathname === "/" ? "active" : ""}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: location.pathname === "/" ? 1 : 0,
                      background: location.pathname !== "/" && "transparent",
                      "&:hover": {
                        boxShadow: 1,
                      },
                    }}
                  >
                    Home
                  </Button>
                </Link>
              </li>
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link
                  to="/about"
                  className={location.pathname === "/about" ? "active" : ""}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: location.pathname === "/about" ? 1 : 0,
                      background:
                        location.pathname !== "/about" && "transparent",
                      "&:hover": {
                        boxShadow: 1,
                      },
                    }}
                  >
                    About
                  </Button>
                </Link>
              </li>
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link
                  to="/process"
                  className={location.pathname === "/process" ? "active" : ""}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: location.pathname === "/process" ? 1 : 0,
                      background:
                        location.pathname !== "/process" && "transparent",
                      "&:hover": {
                        boxShadow: 1,
                      },
                    }}
                  >
                    Process
                  </Button>
                </Link>
              </li>
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link
                  to="/product"
                  className={location.pathname === "/product" ? "active" : ""}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: location.pathname === "/product" ? 1 : 0,
                      background:
                        location.pathname !== "/product" && "transparent",
                      "&:hover": {
                        boxShadow: 1,
                      },
                    }}
                  >
                    Product
                  </Button>
                </Link>
              </li>
              <li className="list-inline-item" onClick={props.closeNav}>
                <Link
                  to="/contact"
                  className={location.pathname === "/contact" ? "active" : ""}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: location.pathname === "/contact" ? 1 : 0,
                      background:
                        location.pathname !== "/contact" && "transparent",
                      "&:hover": {
                        boxShadow: 1,
                      },
                    }}
                  >
                    Contact
                  </Button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </nav>
  );
};

export default Navigation;
