import React from "react";

import OurProcess from "../../Components/Process/OurProcess/OurProcess";
import OurPromises from "../../Components/Process/OurPromises/OurPromises";
import TrendingProducts from "../../Components/Process/TrendingProducts/TrendingProducts";

const OurProcessPage = () => {
  return (
    <>
      <OurProcess />
      <OurPromises />
      <TrendingProducts />
    </>
  );
};

export default OurProcessPage;
