import React, { useEffect, useState, useRef } from "react";
import audio from "../audio/audio.mp3";
import { io } from "socket.io-client";
import { useSnackbar } from "../context/SnackbarProvider";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AdminAppBar from "./AppBar/AdminAppBar";
import AdminSideBar from "./SideBar/AdminSideBar";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";

const drawerWidth = 240;

export default function AdminLayout() {
  const audioRef = useRef(new Audio(audio));
  const socket = useRef(null);
  const { showSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // Initialize socket connection
    socket.current = io(process.env.REACT_APP_API_URL);
    socket.current.on('newOrder', (order) => {
      console.log("New order received:", order);
      // setTotalOrders((prevCount) => prevCount + 1);
      playSound();
      showSnackbar("New order received!", "success", "#aadbaa");
    });

    // Clean up on component unmount
    return () => {
      socket.current.disconnect();
    };
  }, []);


  const playSound = () => {
    audioRef.current.play();
  };

  return (
    <Box sx={{ display: { xs: "block", md: "flex" } }}>
      <CssBaseline />
      <AdminAppBar handleDrawerToggle={handleDrawerToggle} />
      <AdminSideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        variant="temporary"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* Rendering child components here in Outlet */}
        <Outlet />
      </Box>
    </Box>
  );
}
