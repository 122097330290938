"use client";

import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  styled,
  tableCellClasses,
  TablePagination,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useSnackbar } from "../../../context/SnackbarProvider";
import {
  deleteData,
  editData,
  fetchDataFromApi,
} from "../../../utils/api";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primaryColor)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ContactList = () => {
  const { showSnackbar } = useSnackbar();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterTab, setFilterTab] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();

  useEffect(() => {

    if (!token || !user || user.role !== "admin") {
      showSnackbar("Forbidden: Insufficient Permissions.", "error", "#f1b9b9");
      history('/')
    }

    const fetchContacts = async () => {
      setLoading(true);
      fetchDataFromApi(`/api/contact`).then((res) => {
        setContacts(res);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
      setLoading(false);
    };

    fetchContacts();
  }, []);

  const handleDeleteClick = (id) => {
    setContactToDelete(id);
    setConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (contactToDelete) {
      await deleteData(`/api/contact/${contactToDelete}`).then((res) => {
        if (res) {
          setContacts(contacts.filter((contact) => contact._id !== contactToDelete));
          showSnackbar("Contact Query Deleted!", "success", "#aadbaa");
        }
      });
    }
    setConfirmDialogOpen(false);
    setContactToDelete(null);
  };

  const handleQuerySolvedChange = async (id, contact, newStatus) => {

    contact.querySolved = newStatus;

    editData(`/api/contact/${id}`, contact).then((res) => {
      showSnackbar("Status Updated Successfully!!", "success", "#aadbaa");
      fetchDataFromApi(`/api/contact`).then((res) => {
        setContacts(res);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${d.getFullYear()}`;
  };

  const handleTabChange = (event, newValue) => {
    setFilterTab(newValue);
  };

  // Filter contacts based on selected tab
  const filteredContacts = contacts.filter((contact) => {
    if (filterTab === 0) return contact.querySolved === "No"; // Unsolved
    if (filterTab === 1) return contact.querySolved === "Processing"; // Processing
    if (filterTab === 2) return contact.querySolved === "Yes"; // Solved
    return true;
  });

  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "1rem",
          fontWeight: "600",
          color: "var(--textHeading)",
          marginBottom: "2rem",
        }}
      >
        Dashboard / User Queries
      </Typography>

      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "2rem" }}
      >
        <Tabs
          value={filterTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="Unsolved Query" />
          <Tab label="Processing Query" />
          <Tab label="Solved Query" />
        </Tabs>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card
          sx={{
            overflowX: "auto",
            borderRadius: 3,
            boxShadow: 2,
            border: "1px solid var(--borderCool)",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              boxShadow: 0,
              borderRadius: "0",
              maxHeight: "75vh",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <strong>Full Name</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Email</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Subject</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Concern</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Date</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Query Solved</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Action</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredContacts.length > 0 ? (
                  filteredContacts.map((contact, index) => (
                    <StyledTableRow key={contact._id}>
                      <StyledTableCell style={{ minWidth: "150px" }}>
                        {`${contact.firstName} ${contact.lastName}`}
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: "150px" }}>
                        {contact.email}
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: "200px" }}>
                        {contact.subject}
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: "250px" }}>
                        {contact.concern}
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: "150px" }}>
                        {formatDate(contact.createdAt)}
                      </StyledTableCell>
                      <StyledTableCell style={{ minWidth: "150px" }}>
                        <FormControl fullWidth>
                          <InputLabel
                            id={`status-label-${contact._id}`}
                            sx={{
                              background: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
                            }}
                          >
                            Query Solved
                          </InputLabel>
                          <Select
                            label=""
                            labelId={`status-label-${contact._id}`}
                            value={contact.querySolved}
                            onChange={(e) =>
                              handleQuerySolvedChange(
                                contact._id,
                                contact,
                                e.target.value
                              )
                            }
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Processing">Processing</MenuItem>
                          </Select>
                        </FormControl>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: "50px",
                          display: "flex",
                          gap: "1rem",
                        }}
                      >
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteClick(contact._id)}
                          sx={{ border: "1px solid var(--borderCool)" }}
                        >
                          <AiOutlineDelete style={{ fontSize: "1.15rem" }} />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography>
                        <strong>No any contact query available.</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* TablePagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredContacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Card>
      )}

      {/* Delete confirmation dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Delete Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user query?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactList;
