import React, { useState } from 'react';
import { postData } from '../../utils/api';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Snackbar,
    Alert,
    Checkbox,
    FormControlLabel,
    MenuItem,
} from '@mui/material';

const hoursOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
const minutesOptions = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

const WarehouseUpdation = () => {
    const [result, setResult] = useState(null);
    const [sameAsPrimary, setSameAsPrimary] = useState(true);

    const [warehouseDetails, setWarehouseDetails] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        pin: '',
        return_address: '',
        return_pin: '',
        return_city: '',
        return_state: '',
        return_country: '',
        business_days: ["MON", "TUE", "WED", "THU", "FRI", "SAT"],
        business_hours: {
            MON: { start_time: "09:30", close_time: "18:30" },
            TUE: { start_time: "09:30", close_time: "18:30" },
            WED: { start_time: "09:30", close_time: "18:30" },
            THU: { start_time: "09:30", close_time: "18:30" },
            FRI: { start_time: "09:30", close_time: "18:30" },
            SAT: { start_time: "09:30", close_time: "18:30" },
            SUN: { start_time: "", close_time: "" },
        },
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWarehouseDetails((prevState) => ({
            ...prevState,
            [name]: value,
            ...(sameAsPrimary && ['address', 'city', 'country', 'pin'].includes(name)
                ? { [`return_${name}`]: value }
                : {}),
        }));
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSameAsPrimary(isChecked);

        if (isChecked) {
            setWarehouseDetails((prevState) => ({
                ...prevState,
                return_address: prevState.address,
                return_city: prevState.city,
                return_country: prevState.country,
                return_pin: prevState.pin,
                return_state: '',
            }));
        }
    };

    const handleBusinessDayChange = (day) => {
        setWarehouseDetails((prevState) => {
            const isDaySelected = prevState.business_days.includes(day);

            return {
                ...prevState,
                business_days: isDaySelected
                    ? prevState.business_days.filter((d) => d !== day) // Remove the day
                    : [...prevState.business_days, day], // Add the day
                business_hours: isDaySelected
                    ? Object.fromEntries(
                        Object.entries(prevState.business_hours).filter(([key]) => key !== day)
                    ) // Remove the day's hours
                    : {
                        ...prevState.business_hours,
                        [day]: { start_time: "09:30", close_time: "18:30" }, // Add default hours
                    },
            };
        });
    };

    const handleBusinessHourChange = (day, field, value) => {
        setWarehouseDetails((prevState) => ({
            ...prevState,
            business_hours: {
                ...prevState.business_hours,
                [day]: {
                    ...prevState.business_hours[day],
                    [field]: value,
                },
            },
        }));
    };



    // const handleBusinessDayChange = (day) => {
    //     setWarehouseDetails((prevState) => ({
    //         ...prevState,
    //         business_days: prevState.business_days.includes(day)
    //             ? prevState.business_days.filter((d) => d !== day)
    //             : [...prevState.business_days, day],
    //     }));
    // };

    // const handleBusinessHourChange = (day, field, value) => {
    //     setWarehouseDetails((prevState) => ({
    //         ...prevState,
    //         business_hours: {
    //             ...prevState.business_hours,
    //             [day]: {
    //                 ...prevState.business_hours[day],
    //                 [field]: value,
    //             },
    //         },
    //     }));
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const res = await postData(`/api/delhivery/edit-warehouse`, warehouseDetails);
            setResult(res);
            setOpenSnackbar(true);
        } catch (err) {
            setError('Failed to update warehouse. Please try again.');
            console.log('Error:', err);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h4" align="center" gutterBottom>
                Update Delhivery Warehouse
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {/* Primary Address Fields */}
                    <Grid item xs={12}>
                        <TextField
                            label="Warehouse Name"
                            name="name"
                            value={warehouseDetails.name}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={warehouseDetails.email}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={warehouseDetails.phone}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address"
                            name="address"
                            value={warehouseDetails.address}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={warehouseDetails.city}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Pin Code*"
                            name="pin"
                            value={warehouseDetails.pin}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Country"
                            name="country"
                            value={warehouseDetails.country}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>

                    {/* Checkbox for Same as Primary Address */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameAsPrimary}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Return address is the same as the primary address"
                        />
                    </Grid>
                    {/* Return Address Fields */}
                    {!sameAsPrimary && (
                        <>
                            <Typography variant="h6" align="left" gutterBottom>
                                Return Address
                            </Typography>
                            <Grid item xs={12}>
                                <TextField
                                    label="Return Address"
                                    name="return_address"
                                    value={warehouseDetails.return_address}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return City"
                                    name="return_city"
                                    value={warehouseDetails.return_city}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return State"
                                    name="return_state"
                                    value={warehouseDetails.return_state}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return Pin Code"
                                    name="return_pin"
                                    value={warehouseDetails.return_pin}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return Country"
                                    name="return_country"
                                    value={warehouseDetails.return_country}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}

                    {/* Business Days and Hours */}
                    <Grid item xs={12}>
                        <Typography variant="h6">Business Days and Hours</Typography>
                    </Grid>
                    {["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((day) => (
                        <Grid item xs={12} key={day}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={warehouseDetails.business_days.includes(day)}
                                        onChange={() => handleBusinessDayChange(day)}
                                    />
                                }
                                label={day}
                            />
                            {warehouseDetails.business_days.includes(day) && (
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label={`${day} Start Hour`}
                                            value={warehouseDetails.business_hours[day].start_time.split(":")[0]}
                                            onChange={(e) =>
                                                handleBusinessHourChange(
                                                    day,
                                                    "start_time",
                                                    `${e.target.value}:${warehouseDetails.business_hours[day].start_time.split(":")[1]}`
                                                )
                                            }
                                            fullWidth
                                        >
                                            {hoursOptions.map((hour) => (
                                                <MenuItem key={hour} value={hour}>
                                                    {hour}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label={`${day} Start Minute`}
                                            value={warehouseDetails.business_hours[day].start_time.split(":")[1]}
                                            onChange={(e) =>
                                                handleBusinessHourChange(
                                                    day,
                                                    "start_time",
                                                    `${warehouseDetails.business_hours[day].start_time.split(":")[0]}:${e.target.value}`
                                                )
                                            }
                                            fullWidth
                                        >
                                            {minutesOptions.map((minute) => (
                                                <MenuItem key={minute} value={minute}>
                                                    {minute}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label={`${day} Close Hour`}
                                            value={warehouseDetails.business_hours[day].close_time.split(":")[0]}
                                            onChange={(e) =>
                                                handleBusinessHourChange(
                                                    day,
                                                    "close_time",
                                                    `${e.target.value}:${warehouseDetails.business_hours[day].close_time.split(":")[1]}`
                                                )
                                            }
                                            fullWidth
                                        >
                                            {hoursOptions.map((hour) => (
                                                <MenuItem key={hour} value={hour}>
                                                    {hour}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label={`${day} Close Minute`}
                                            value={warehouseDetails.business_hours[day].close_time.split(":")[1]}
                                            onChange={(e) =>
                                                handleBusinessHourChange(
                                                    day,
                                                    "close_time",
                                                    `${warehouseDetails.business_hours[day].close_time.split(":")[0]}:${e.target.value}`
                                                )
                                            }
                                            fullWidth
                                        >
                                            {minutesOptions.map((minute) => (
                                                <MenuItem key={minute} value={minute}>
                                                    {minute}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Warehouse updated successfully!
                </Alert>
            </Snackbar>

            {result && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                </Alert>
            )}

        </Container>
    );
};

export default WarehouseUpdation;