import React, { useContext, useEffect } from "react";
import axios from 'axios';
import Button from "@mui/material/Button";
import { FaAngleDown } from "react-icons/fa6";
import Dialog from "@mui/material/Dialog";
import { IoIosSearch } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import Slide from "@mui/material/Slide";
import { AdminContext } from "../../../App";
import { Link, useParams } from "react-router-dom";
import "./style.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CountryDropdown = (props) => {
  const [isOpenModal, setisOpenModal] = useState(false);
  const [selectedTab, setselectedTab] = useState(null);
  const [countryList, setcountryList] = useState([]);
  const [selectedCountry, setselectedCountry] = useState("");

  const context = useContext(AdminContext);

  const selectCountry = (index, country) => {
    setselectedTab(index);
    setisOpenModal(false);
    setselectedCountry(country);
    props?.setselectedCountry(country)
  };

  let { id } = useParams();

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://countriesnow.space/api/v0.1/countries/");
        setcountryList(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }

      // Set selected country from props
      setselectedCountry(props.selectedLocation);
    };

    fetchCountries();
  }, [props.selectedLocation]);


  const filterList = async (e) => {
    const keyword = e.target.value.toLowerCase();

    if (keyword !== "") {
      const list = countryList.filter((item) => {
        return item.country.toLowerCase().includes(keyword);
      });
      setcountryList(list);
    } else {
      await axios.get("https://countriesnow.space/api/v0.1/countries/").then((res) => {
        setcountryList(res.data.data);
      });
    }
  };

  return (
    <>
      <Button
        className="countryDrop"
        onClick={() => {
          setisOpenModal(true);
          setcountryList(countryList);
        }}
      >
        <div className="info d-flex flex-column">
          <span className="name">
            {selectedCountry !== ""
              ? selectedCountry?.length > 10
                ? selectedCountry?.substr(0, 10) + "..."
                : selectedCountry
              : "Select Location"}
          </span>
        </div>
        <span className="ml-auto">
          <FaAngleDown />
        </span>
      </Button>

      <Dialog
        open={isOpenModal}
        onClose={() => setisOpenModal(false)}
        className="locationModal"
        TransitionComponent={Transition}
      >
        <h4 className="mb-0">Choose your Delivery Location</h4>
        <p>Enter your address and we will specify the offer for your area.</p>
        <Button className="close_" onClick={() => setisOpenModal(false)}>
          <MdClose />
        </Button>

        <div className="headerSearch w-100">
          <input
            type="text"
            placeholder="Search your area..."
            onChange={filterList}
          />
          <Button>
            <IoIosSearch />
          </Button>
        </div>

        <ul className="countryList mt-3">
          {countryList?.length !== 0 &&
            countryList?.map((item, index) => {
              return (
                <li key={index}>
                  <Button
                    onClick={() => selectCountry(index, item.country)}
                    className={`${selectedTab === index ? "active" : ""}`}
                  >
                    {item.country}
                  </Button>
                </li>
              );
            })}
        </ul>
      </Dialog>
    </>
  );
};

export default CountryDropdown;
