"use client";

import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Paper,
  Grid,
  CircularProgress,
  Chip,
  Divider,
} from "@mui/material";
import { useSnackbar } from "../../../../../context/SnackbarProvider";
import { Add, Remove } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { fetchDataFromApi, uploadImage, editData, deleteImages } from "../../../../../utils/api";
import { IoCloseSharp } from "react-icons/io5";

const EditTermsAndConditions = () => {
  const { id } = useParams();
  const { showSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");
  const [slug, setSlug] = useState("");
  const [sections, setSections] = useState([
    { title: "", description: "", points: [{ title: "", description: "" }] },
  ]);
  const [services, setServices] = useState([
    { title: "", description: "", points: [{ title: "", description: "" }] },
  ]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState([""]);
  const [newKeyword, setNewKeyword] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {

      fetchDataFromApi(`/api/terms-and-conditions/${id}`).then((res) => {
        const data = res;
        setTitle(data.title);
        setVersion(data.version);
        setSlug(data.slug);
        setSections(data.sections || sections);
        setServices(data.services || services);
        setMetaTitle(data.metaData?.title || "");
        setMetaDescription(data.metaData?.description || "");
        setMetaKeywords(data.metaData?.keywords || []);
        setDescription(data.description || "");
        setDocument(data.document || "");
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    };

    fetchData();
  }, [id]);

  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
  };

  const handleSectionPointChange = (sectionIndex, pointIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points[pointIndex][field] = value;
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    setSections([
      ...sections,
      { title: "", description: "", points: [{ title: "", description: "" }] },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSections((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddSectionPoint = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].points.push({ title: "", description: "" });
    setSections(updatedSections);
  };

  const handleRemoveSectionPoint = (sectionIndex, pointIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points = updatedSections[sectionIndex].points.filter((_, i) => i !== pointIndex);
    setSections(updatedSections);
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  const handleServicePointChange = (serviceIndex, pointIndex, field, value) => {
    const updatedServices = [...services];
    updatedServices[serviceIndex].points[pointIndex][field] = value;
    setServices(updatedServices);
  };

  const handleAddService = () => {
    setServices([
      ...services,
      { title: "", description: "", points: [{ title: "", description: "" }] },
    ]);
  };

  const handleRemoveService = (index) => {
    setServices((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddServicePoint = (index) => {
    const updatedServices = [...services];
    updatedServices[index].points.push({ title: "", description: "" });
    setServices(updatedServices);
  };

  const handleRemoveServicePoint = (serviceIndex, pointIndex) => {
    const updatedServices = [...services];
    updatedServices[serviceIndex].points = updatedServices[serviceIndex].points.filter((_, i) => i !== pointIndex);
    setServices(updatedServices);
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      setMetaKeywords([...metaKeywords, newKeyword.trim()]);
      setNewKeyword("");
    }
  };

  const handleRemoveKeyword = (index) => {
    setMetaKeywords((prev) => prev.filter((_, i) => i !== index));
  };

  // File Upload Handler
  const onChangeFile = async (e, apiEndPoint) => {
    const formdata = new FormData();
    const file = e.target.files ? e.target.files[0] : null;

    formdata.append('images', file);

    uploadImage(apiEndPoint, formdata).then((res) => {
      setDocument(res)
      showSnackbar("Documents Uploaded Successfully!!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a plain JavaScript object with the form data
    const payload = {
      title,
      version,
      slug,
      metaData: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
      },
      sections,
      services,
      document,
      description,
    };

    try {
      editData(`/api/terms-and-conditions/${id}`, payload).then((res) => {
        showSnackbar("Terms and Conditions updated successfully", "success", "#aadbaa");
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const removeImg = async (imgUrl) => {
    deleteImages(`/api/terms-and-conditions/deleteImage?img=${imgUrl}`).then((res) => {
      setDocument(null)
      showSnackbar("Image Deleted!!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: 3,
          borderRadius: 2,
          border: "1px solid var(--borderCool)",
          background: "var(--bgLight)",
        }}
        elevation={3}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: "1rem",
            fontWeight: "600",
            color: "var(--textHeading)",
            marginBottom: "2rem",
          }}
        >
          Dashboard / Legal / Edit Terms & Conditions
        </Typography>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Version"
                variant="outlined"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Slug"
                variant="outlined"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
            </Grid>
            {/* Description */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <Box>
            <Divider
              sx={{
                height: "2px",
                background: "var(--borderCool)",
                opacity: "0.5",
                margin: "1rem 0",
              }}
            />
          </Box>

          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
              color: "var(--textColor)",
              margin: "1.5rem 0",
              textTransform: "uppercase",
            }}
          >
            Sections
          </Typography>
          {sections.map((section, sectionIndex) => (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "var(--textColor)",
                  mb: 2,
                }}
              >
                {`Section - ${sectionIndex + 1}`}
              </Typography>

              <Paper
                key={sectionIndex}
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  border: "1px solid var(--borderCool)",
                  background: "var(--bgColor)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    mb: 3,
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveSection(sectionIndex)}
                    sx={{ border: "1px solid var(--borderCool)" }}
                  >
                    <RiDeleteBin5Line fontSize={"1rem"} />
                  </IconButton>
                </Box>

                <TextField
                  fullWidth
                  label={`Section ${sectionIndex + 1} Title`}
                  variant="outlined"
                  value={section.title}
                  onChange={(e) =>
                    handleSectionChange(sectionIndex, "title", e.target.value)
                  }
                  sx={{ mb: 2 }}
                  required
                />
                <TextField
                  fullWidth
                  label={`Section ${sectionIndex + 1} Description`}
                  variant="outlined"
                  value={section.description}
                  onChange={(e) =>
                    handleSectionChange(
                      sectionIndex,
                      "description",
                      e.target.value
                    )
                  }
                  sx={{ mb: 2 }}
                  multiline
                  rows={3}
                />

                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Points
                </Typography>
                {section.points.map((point, pointIndex) => (
                  <Grid container spacing={2} key={pointIndex} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label={`Point ${pointIndex + 1} Title`}
                        variant="outlined"
                        value={point.title}
                        onChange={(e) =>
                          handleSectionPointChange(
                            sectionIndex,
                            pointIndex,
                            "title",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label={`Point ${pointIndex + 1} Description`}
                        variant="outlined"
                        value={point.description}
                        onChange={(e) =>
                          handleSectionPointChange(
                            sectionIndex,
                            pointIndex,
                            "description",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <IconButton
                        sx={{
                          border: "1px solid var(--borderCool)",
                        }}
                        color="error"
                        onClick={() =>
                          handleRemoveSectionPoint(sectionIndex, pointIndex)
                        }
                      >
                        <Remove />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleAddSectionPoint(sectionIndex)}
                >
                  Add Point
                </Button>
              </Paper>
            </>
          ))}
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddSection}
          >
            Add Section
          </Button>

          <Box>
            <Divider
              sx={{
                height: "2px",
                background: "var(--borderCool)",
                opacity: "0.5",
                margin: "1rem 0",
              }}
            />
          </Box>

          {/* Services */}
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
              color: "var(--textColor)",
              margin: "1.5rem 0",
              textTransform: "uppercase",
            }}
          >
            Services
          </Typography>
          {services.map((service, serviceIndex) => (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "var(--textColor)",
                  mb: 2,
                }}
              >
                {`Service - ${serviceIndex + 1}`}
              </Typography>
              <Paper
                key={serviceIndex}
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  border: "1px solid var(--borderCool)",
                  background: "var(--bgColor)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    mb: 3,
                  }}
                >
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveService(serviceIndex)}
                    sx={{ border: "1px solid var(--borderCool)" }}
                  >
                    <RiDeleteBin5Line fontSize={"1rem"} />
                  </IconButton>
                </Box>

                <TextField
                  fullWidth
                  label={`Service ${serviceIndex + 1} Title`}
                  variant="outlined"
                  value={service.title}
                  onChange={(e) =>
                    handleServiceChange(serviceIndex, "title", e.target.value)
                  }
                  sx={{ mb: 2 }}
                  required
                />
                <TextField
                  fullWidth
                  label={`Service ${serviceIndex + 1} Description`}
                  variant="outlined"
                  value={service.description}
                  onChange={(e) =>
                    handleServiceChange(
                      serviceIndex,
                      "description",
                      e.target.value
                    )
                  }
                  sx={{ mb: 2 }}
                  multiline
                  rows={3}
                />

                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Points
                </Typography>
                {service.points.map((point, pointIndex) => (
                  <Grid container spacing={2} key={pointIndex} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label={`Point ${pointIndex + 1} Title`}
                        variant="outlined"
                        value={point.title}
                        onChange={(e) =>
                          handleServicePointChange(
                            serviceIndex,
                            pointIndex,
                            "title",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        label={`Point ${pointIndex + 1} Description`}
                        variant="outlined"
                        value={point.description}
                        onChange={(e) =>
                          handleServicePointChange(
                            serviceIndex,
                            pointIndex,
                            "description",
                            e.target.value
                          )
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <IconButton
                        sx={{ border: "1px solid var(--borderCool)" }}
                        color="error"
                        onClick={() =>
                          handleRemoveServicePoint(serviceIndex, pointIndex)
                        }
                      >
                        <Remove />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => handleAddServicePoint(serviceIndex)}
                >
                  Add Point
                </Button>
              </Paper>
            </>
          ))}
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleAddService}
          >
            Add Service
          </Button>

          <Box>
            <Divider
              sx={{
                height: "2px",
                background: "var(--borderCool)",
                opacity: "0.5",
                margin: "1rem 0",
              }}
            />
          </Box>

          {/* Meta Data */}
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: "1rem",
              fontWeight: "500",
              color: "var(--textColor)",
              margin: "1.5rem 0",
              textTransform: "uppercase",
            }}
          >
            Meta Data
          </Typography>
          <TextField
            fullWidth
            label="Meta Title"
            variant="outlined"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Meta Description"
            variant="outlined"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            sx={{ mb: 2 }}
            multiline
            rows={3}
            required
          />
          <Box sx={{ display: "flex", mb: 2, gap: "1rem" }}>
            <TextField
              label="Meta Keywords"
              variant="outlined"
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
            />
            <Button variant="contained" onClick={handleAddKeyword}>
              Add
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {metaKeywords.map((keyword, index) => (
              <Chip
                key={index}
                label={keyword}
                variant="outlined"
                onDelete={() => handleRemoveKeyword(index)}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>

          <Box>
            <Divider
              sx={{
                height: "2px",
                background: "var(--borderCool)",
                opacity: "0.5",
                margin: "1rem 0",
              }}
            />
          </Box>

          {/* Document Upload */}
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                color: "var(--textColor)",
                margin: "1.5rem 0",
                textTransform: "uppercase",
              }}
            >
              Upload Document (PDF)
            </Typography>

            <Box>
              <Button variant="contained" component="label">
                Upload Document
                <input
                  type="file"
                  hidden
                  onChange={(e) =>
                    onChangeFile(e, "/api/images/upload")
                  }
                  accept=".pdf"
                />
              </Button>
              {/* Display the file name if selected */}
              {document && (
                <Typography mt={2}>
                  Selected File: {document}
                  <span
                    className="remove"
                    onClick={() => removeImg(document)}
                  >
                    <IoCloseSharp />
                  </span>
                </Typography>
              )}
            </Box>
          </Box>

          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            fullWidth
            sx={{ mt: 3 }}
          >
            {loading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </form>
      </Paper>
    </Box>
  );
};

export default EditTermsAndConditions;
