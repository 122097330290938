import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import { Card, CardMedia } from "@mui/material";

import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useRef, useState } from "react";
import "./style.css";

const ProductZoom = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const zoomSliderBig = useRef();
  const zoomSlider = useRef();

  const goto = (index) => {
    setSlideIndex(index);
    zoomSlider.current.swiper.slideTo(index);
    zoomSliderBig.current.swiper.slideTo(index);
  };

  return (
    <div className="productZoom">
      <div className="position-relative mb-3">
        <div
          className="badge"
          style={{
            background: "var(--secondaryColor)",
            color: "var(--textColor)",
            border: "1px solid var(--primaryColor)",
          }}
        >
          {props?.discount}%
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          navigation={false}
          slidesPerGroup={1}
          modules={[Navigation]}
          className="zoomSliderBig"
          ref={zoomSliderBig}
        >
          {props?.images?.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="item">
                  <InnerImageZoom zoomType="hover" zoomScale={1} src={img} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <Swiper
        slidesPerView={3}
        ref={zoomSlider}
        spaceBetween={5}
        navigation={false}
        loop={false}
        centeredSlides={false}
        className="mb-5"
        breakpoints={{
          200: {
            slidesPerView: 3,
          },
          340: {
            slidesPerView: 4,
          },
          450: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {props?.images?.map((img, index) => {
          return (
            <SwiperSlide>
              <Card
                key={index}
                onClick={() => goto(index)}
                sx={{
                  boxShadow: slideIndex === index ? 3 : 0,
                  border:
                    slideIndex === index
                      ? "1.5px solid var(--primaryColor)"
                      : "1px solid rgba(0, 0, 0, 0.45)",
                  width: "100%",
                  opacity: slideIndex === index ? "1" : "0.45",
                  width: "70px",
                  height: "70px",
                  margin: "0.5rem 0.35rem",
                  cursor: "pointer",
                  borderRadius: 2,
                }}
              >
                <CardMedia
                  component="img"
                  image={img}
                  alt={`Product Image ${index}`}
                  sx={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ProductZoom;
