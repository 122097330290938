import React, { useEffect, useState } from "react";
import ProductItem from "../../Components/ProductItem";
import HomeCat from "../../Components/HomeCat";
import { fetchDataFromApi } from "../../utils/api";
import { Grid, CircularProgress } from "@mui/material";
import { useSnackbar } from "../../context/SnackbarProvider";

const ProductsPage = () => {
  const [productsData, setProductsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    const location = localStorage.getItem("location");

    if (location !== null && location !== "" && location !== undefined) {
      fetchDataFromApi(`/api/products?page=1&perPage=20&location=${location}`).then((res) => {
        setProductsData(res);
        setIsLoading(false);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    }

    fetchDataFromApi("/api/category").then((res) => {
      setCategoryData(res.categoryList);

      const subCatArr = [];

      res.categoryList?.length !== 0 &&
        res.categoryList?.map((cat, index) => {
          if (cat?.children.length !== 0) {
            cat?.children?.map((subCat) => {
              subCatArr.push(subCat);
            });
          }
        });

      setsubCategoryData(subCatArr);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

  }, []);

  return (
    <>
      {categoryData?.length !== 0 && (
        <HomeCat catData={categoryData} />
      )}

      <section className="container">
        <div className="info mt-3 w-100">
          <h3 className="mb-1 hd">All Products</h3>
          <p className="text-light text-sml mb-3">
            Explore a wide range of products.
          </p>
        </div>

        <div
          className="w-100 mt-2"
          style={{ opacity: `${isLoading === true ? "0.5" : "1"}` }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <CircularProgress />
            </div>
          ) : (
            <Grid container columnSpacing={2} rowSpacing={3}>
              {productsData?.length !== 0 &&
                productsData?.products?.slice(0)?.map((item, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                    <ProductItem item={item} />
                  </Grid>
                ))}
            </Grid>
          )}
        </div>
      </section>
    </>
  );
};

export default ProductsPage;
