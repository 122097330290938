import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";
import { useSnackbar } from "../../context/SnackbarProvider";
import { fetchDataFromApi, postData } from "../../utils/api";
import "./style.css";
import { useNavigate } from "react-router-dom";
import CountryDropdown from "../../Components/CountryDropdown";
import axios from "axios";
import Select from '@mui/material/Select';
import { MenuItem } from "@mui/material";
import{ FormControl }from "@mui/material";
import {InputLabel} from "@mui/material";
import { checkPincodeServiceability } from "../../utils/api";

const Checkout = () => {
  const { showSnackbar } = useSnackbar();
  const [deliverable, setdeliverable] = useState({success:true,message:"",city:""});
  const [formFields, setFormFields] = useState({
    fullName: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
  });

  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
      setCartData(res);

      setTotalAmount(
        res.length !== 0 &&
        res
          .map((item) => parseInt(item.subTotal))
          .reduce((total, value) => total + value, 0)
      );
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
    getCountry("https://countriesnow.space/api/v0.1/countries/");
  }, []);

  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };
  const getCountry = async (url) => {
    await axios.get(url).then((res) => {
      setCountryList(res.data.data);

    });
  };
 
  const history = useNavigate();

  const checkPinCode = async() =>{
    console.log(formFields.zipCode);
    if(formFields?.zipCode?.length == 6){
    checkPincodeServiceability("/api/delhivery/pincode-serviceability/",formFields.zipCode).then((res)=>{
       console.log(res)
      if(res?.delivery_codes?.length > 0){
        setdeliverable({
          success:true,
          city:res?.delivery_codes[0]?.postal_code?.city,
          message:"availble"
        })
      }
      else{
        setdeliverable({
          success:false,
          city:"",
          message:"item not availble to this zipcode"
        })
      }
    })
  }
  else if(formFields?.zipCode?.length == 0){
    setdeliverable({
      success:true,
      city:"",
      message:""
    })
  }
  }
  const checkout = (e) => {
    e.preventDefault();

    if (formFields.fullName === "") {
      showSnackbar("Please fill full name ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.country === "") {
      showSnackbar("Please fill country ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.streetAddressLine1 === "") {
      showSnackbar("Please fill Street address", "error", "#f1b9b9");
      return false;
    }

    if (formFields.streetAddressLine2 === "") {
      showSnackbar("Please fill  Street address", "error", "#f1b9b9");
      return false;
    }

    if (formFields.city === "") {
      showSnackbar("Please fill city ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.state === "") {
      showSnackbar("Please fill state ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.zipCode === "") {
      showSnackbar("Please fill zipCode ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.phoneNumber === "") {
      showSnackbar("Please fill phone Number ", "error", "#f1b9b9");
      return false;
    }

    if (formFields.email === "") {
      showSnackbar("Please fill email", "error", "#f1b9b9");
      return false;
    }

    const addressInfo = {
      name: formFields.fullName,
      phoneNumber: formFields.phoneNumber,
      address: formFields.streetAddressLine1 + " " + formFields.streetAddressLine2,
      pincode: formFields.zipCode,
      city: formFields.city,
      country:formFields.country,
      state: formFields.state,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };
    console.log(addressInfo)

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: parseInt(totalAmount * 100),
      currency: "INR",
      order_receipt: "order_rcptid_" + formFields.fullName,
      name: "Royal Beekeeper",
      description: "for testing purpose",
      handler: function (response) {
        const paymentId = response.razorpay_payment_id;

        const user = JSON.parse(localStorage.getItem("user"));

        const payLoad = {
          name: addressInfo.name,
          phoneNumber: formFields.phoneNumber,
          address: addressInfo.address,
          pincode: addressInfo.pincode,
          city: addressInfo.city,
          country: addressInfo.country,
          state: addressInfo.state,
          amount: parseInt(totalAmount),
          paymentId: paymentId,
          email: user.email,
          userid: user.userId,
          products: cartData,
        };
       
        postData(`/api/orders/create`, payLoad).then((res) => {
          history("/orders");
        });
      },

      theme: {
        color: "#3399cc",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();
  };

  return (

    <section className="section">
      <div className="container">
        <form className="checkoutForm" onSubmit={checkout}>
          <div className="row">
            <div className="col-md-8">
              <h2 className="hd">BILLING/SHIPPING DETAILS</h2>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Full Name *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="fullName"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                    <Select
                   
                      id="country"
                      name="country"
                      value={formFields.country}
                      label="Contry"
                      onChange={onChangeInput}
                    >
                      {countryList.map((item,index)=><MenuItem value={item.country} >{item.country}</MenuItem>)}
                    
                    </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <h6>Street address *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="House number and street name"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine1"
                      onChange={onChangeInput}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      label="Apartment, suite, unit, etc."
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine2"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Town / City *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="City"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="city"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>State / County *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="State"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="state"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Postcode / ZIP *</h6>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="ZIP Code"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="zipCode"
                      onChange={onChangeInput}
                      onBlur={checkPinCode}
                      inputProps={{ maxLength: 6 }}
                      error={!deliverable.success}
                      helperText={deliverable.success ? deliverable.city:deliverable.message}
                    />

                  </div>
                 
                </div>
              
               
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="phoneNumber"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Email Address"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="email"
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card orderInfo">
                <h4 className="hd">YOUR ORDER</h4>
                <div className="table-responsive mt-3">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartData?.length !== 0 &&
                        cartData?.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                {item?.productTitle?.substr(0, 20) + "..."}{" "}
                                <b>× {item?.quantity}</b>
                              </td>

                              <td>
                                {item?.subTotal?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "INR",
                                })}
                              </td>
                            </tr>
                          );
                        })}

                      <tr>
                        <td>Subtotal </td>

                        <td>
                          {(cartData?.length !== 0
                            ? cartData
                              ?.map(
                                (item) => parseInt(item.price) * item.quantity
                              )
                              .reduce((total, value) => total + value, 0)
                            : 0
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Button
                  type="submit"
                  className="btn-blue bg-red btn-lg btn-big"
                >
                  <IoBagCheckOutline /> &nbsp; Checkout
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
