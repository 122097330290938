import './style.css';
import logo from '../../assets/images/logo.png';
import { useState, useEffect, useRef } from 'react';
import { fetchDataFromApi } from '../../utils/api';
import { useParams } from 'react-router-dom';
import {
   Box,
   Typography,
   Button,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Paper,
   Grid,
} from '@mui/material';

function Invoice() {
   const { id } = useParams();
   const [order, setOrder] = useState([]);
   const invoiceRef = useRef(null); // Define the ref

   useEffect(() => {
      fetchDataFromApi(`/api/orders/${id}`).then((res) => {
         setOrder(res);
         document.title = `${order.name ? order.name : ""} Invoice`;
      });
      // Set the document title to "Invoice"

      // Optional cleanup to reset the title on unmount
      return () => {
         document.title = "Full Stack E-commerce Web App"; // Reset title when leaving
      };

   }, [id]);

   function handlePrint() {
      if (!invoiceRef.current) {
         console.error("Invoice element not found");
         return;
      }

      // Temporarily apply print styles for A4
      const style = document.createElement("style");
      style.innerHTML = `
        @media print {
            body {
                margin: 0;
                padding: 0;
            }
            .invoice-main-box {
                width: 210mm;
                height: auto;
                padding: 20mm;
                page-break-after: always;
            }
        }
    `;
      document.head.appendChild(style);

      // Print content
      const originalContents = document.body.innerHTML;
      const invoiceContents = invoiceRef.current.innerHTML;

      document.body.innerHTML = invoiceContents;
      window.print();

      // Restore content
      document.body.innerHTML = originalContents;
      window.location.reload();
   }


   const formatDate = (date) => {
      const d = new Date(date);
      return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1)
         .toString()
         .padStart(2, '0')}-${d.getFullYear()}`;
   };

   return (
      <Box
         ref={invoiceRef} // Attach the ref here
         sx={{
            maxWidth: 800,
            margin: '0 auto',
            padding: 2,
            border: '1px solid #ccc',
            backgroundColor: '#fff',
         }}
      >
         <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
               <Box
                  component="img"
                  src={logo}
                  alt="Company Logo"
                  sx={{ width: 130, height: 130, borderRadius: '50%', border: 1, background: "rgba(0, 0, 0, 0.8)" }}
               />
            </Grid>
            <Grid item xs={8}>
               <Typography variant="h4" align="right">
                  Royal Beekeeper
               </Typography>
               <Typography variant="body1" align="right">
                  Ward No. 17, Karanpur Road, Purani Abadi Sri Ganganagar, Rajasthan - 335001
               </Typography>
            </Grid>
         </Grid>

         <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body1">
               <strong>PAN NO:</strong> FZEPP1318A
            </Typography>
            <Typography variant="body1">
               <strong>FSSAI License NO:</strong> 22224022000313
            </Typography>
         </Box>

         <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={6}>
               <Typography variant="h6">Billing Address</Typography>
               <Typography>{order.name}</Typography>
               <Typography>{`${order.address}, ${order.city}, ${order.state}, ${order.country}, ${order.pincode}`}</Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant="h6">Shipping Address</Typography>
               <Typography>{order.name}</Typography>
               <Typography>{`${order.address}, ${order.city}, ${order.state}, ${order.country}, ${order.pincode}`}</Typography>
            </Grid>
         </Grid>

         <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={6}>
               <Typography>
                  <strong>Order Number:</strong> {order._id}
               </Typography>
               <Typography>
                  <strong>Order Date:</strong> {formatDate(order.date)}
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography>
                  <strong>Invoice Number:</strong> {order._id}
               </Typography>
               <Typography>
                  <strong>Invoice Date:</strong> {formatDate(order.date)}
               </Typography>
            </Grid>
         </Grid>

         <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>Description</TableCell>
                     <TableCell>Unit Price</TableCell>
                     <TableCell>Qty</TableCell>
                     <TableCell>Delivery Charge</TableCell>
                     <TableCell>Total Amount</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {order?.products?.map((product, index) => (
                     <TableRow key={index}>
                        <TableCell>{product.productTitle}</TableCell>
                        <TableCell>₹{product.price}</TableCell>
                        <TableCell>{product.quantity}</TableCell>
                        <TableCell>Free</TableCell>
                        <TableCell>₹{product.subTotal}</TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>

         <Typography variant="body1">
            <strong>Payment Transaction ID:</strong> {order.paymentId}
         </Typography>
         <Typography variant="body1">
            <strong>Mode of Payment:</strong> Online
         </Typography>

         <Typography variant="body1" sx={{ fontWeight: 'bold', marginY: 2 }}>
            Date & Time: {order.date}
         </Typography>
         <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Total Amount: ₹{order.amount}
         </Typography>

         <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={handlePrint}>
               Print
            </Button>
         </Box>
      </Box>
   );
}

export default Invoice;
