import React from "react";
import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { GiCheckMark } from "react-icons/gi";
import "./style.css";
import TrendingProducts from "../../Components/Process/TrendingProducts/TrendingProducts";

// Images
import aboutUsImage from "../../assets/images/about/about-us.jpg";
import ourStoryImage from "../../assets/images/about/our-story.jpg";
import ourCommitmentImage from "../../assets/images/about/our-commitment.jpg";

const aboutData = [
  {
    title: "About Royal BeeKeeper",
    description:
      "Royal BeeKeeper is dedicated to bringing the purest and most natural honey and bee-based products straight from the hive to your home. Established with a passion for quality and sustainability, we offer a range of honey products, including raw honey, honey mixed with dried fruits, bee pollen, royal jelly, bee wax, propolis, and bee tinctures. Our goal is to promote health and wellness through the natural benefits of bee products.",
    image: aboutUsImage,
    points: [
      "Specialized in natural, high-quality honey and bee products.",
      "Commitment to quality and purity in every jar.",
      "Offering a diverse range of products for health and wellness.",
    ],
  },
  {
    title: "Our Story",
    description:
      "Our journey began with a passion for beekeeping and a love for nature’s sweet gifts. Over the years, we have expanded from offering pure honey to a diverse range of products like honey mixed with dry fruits, bee pollen, and royal jelly. We believe in providing natural, wholesome products that benefit both your health and the environment.",
    image: ourStoryImage,
    points: [
      "Started with a passion for beekeeping and natural products.",
      "Now offering a wide range of honey and bee-related products.",
      "Focused on natural, sustainable, and eco-friendly practices.",
    ],
  },
  {
    title: "Our Commitment",
    description:
      "At Royal BeeKeeper, we are committed to offering only the best bee-derived products while supporting the vital role bees play in our ecosystem. We believe in delivering natural products that are free from artificial additives, and we work closely with local beekeepers to support sustainable beekeeping practices. Every item, from honey to royal jelly, is crafted with care, ensuring our customers receive only the best and most natural benefits.",
    image: ourCommitmentImage,
    points: [
      "Dedicated to purity and sustainability in all products.",
      "Supporting sustainable and eco-friendly beekeeping practices.",
      "Ensuring every product is 100% natural and packed with health benefits.",
    ],
  },
];

const AboutUsPage = () => {
  return (
    <Box component="section">
      <Box className="container about-collage">
        {aboutData.map((feature, index) => (
          <Box className="feature-box" key={index}>
            <Box
              className={`row about ${index % 2 === 0 ? "about-row" : "about-row-reverse"
                }`}
            >
              <Box className="col-12 col-sm-12 col-md-12 col-lg-6 order-lg-first order-last content-box">
                <Typography variant="h2" className="heading">
                  {feature.title}
                </Typography>
                <Typography variant="body1" className="description">
                  {feature.description}
                </Typography>
                <List className="list">
                  {feature.points.map((point, i) => (
                    <ListItem key={i} disablePadding className="list-item">
                      <GiCheckMark className="icon" />
                      <ListItemText
                        className="item"
                        primary={`${point}`}
                        primaryTypographyProps={{ fontSize: "0.9rem" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* Image Section */}
              <Box className="col-12 col-sm-12 col-md-12 col-lg-6 order-md-first order-sm-first">
                <Box
                  className={`image-box ${index % 2 === 0 ? "left-margin" : "right-margin"
                    }`}
                >
                  <Box
                    component="img"
                    className="image"
                    src={feature.image}
                    alt={feature.title}
                  />
                </Box>
              </Box>
            </Box>

            {index !== aboutData.length - 1 && <Divider className="divider" />}
          </Box>
        ))}
      </Box>

      <TrendingProducts />
    </Box>
  );
};

export default AboutUsPage;
