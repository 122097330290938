import React, { useState } from "react";
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Box,
    Snackbar,
    Alert,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
} from "@mui/material";
import { postData } from "../../utils/api";

const CreateOrderForm = () => {
    const [shipmentType, setShipmentType] = useState("Forward Shipment");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState("");
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        shipments: [
            {
                add: "",
                address_type: "home",
                phone: "",
                payment_mode: "Prepaid",
                name: "",
                pin: "",
                order: "",
                country: "India",
                cod_amount: 0,
                waybill: "",
                shipping_mode: "Surface",
                fragile_shipment: false, // Ensure default boolean
                gst_details: {
                    seller_gst_tin: "",
                    client_gst_tin: "",
                    hsn_code: [""],
                    invoice_reference: "",
                },
            },
        ],
        pickup_location: {
            name: "",
            city: "",
            pin: "",
            country: "India",
            phone: "",
            add: "",
        },
    });

    const handleShipmentTypeChange = (e) => {
        setShipmentType(e.target.value);
    };

    const handleChange = (e, field, shipmentIndex = 0) => {
        let { name, value, type, checked } = e.target;
        let fieldValue = type === "checkbox" ? checked : value;

        if (name === "cod_amount") {
            fieldValue = parseFloat(value) || 0; // Ensure it stays a float
        }

        if (field === "pickup_location") {
            setFormData((prev) => ({
                ...prev,
                pickup_location: { ...prev.pickup_location, [name]: fieldValue },
            }));
        } else if (field.startsWith("gst_details")) {
            const [_, gstField] = field.split(".");
            const updatedShipments = [...formData.shipments];
            updatedShipments[shipmentIndex].gst_details[gstField] = fieldValue;
            setFormData({ ...formData, shipments: updatedShipments });
        } else {
            const updatedShipments = [...formData.shipments];
            updatedShipments[shipmentIndex] = {
                ...updatedShipments[shipmentIndex],
                [name]: fieldValue,
            };
            setFormData({ ...formData, shipments: updatedShipments });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const res = await postData("/api/delhivery/create-shipment", formData);
            setResult(res);
            setOpenSnackbar(true);
        } catch (err) {
            setError(err.response?.data?.message || "Failed to create order. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const addressTypeOptions = ["home", "office"];
    const shippingModeOptions = ["Surface", "Express"];
    const paymentModeOptions = {
        "Forward Shipment": ["Prepaid", "COD"],
        "Reverse Shipment": ["Pickup"],
        "REPL Shipment": ["REPL"],
    };

    return (
        <Container maxWidth="md">
            <Box mt={4} mb={2}>
                <Typography variant="h4" align="center">
                    Create New Order
                </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Pickup Location</Typography>
                    </Grid>
                    {["name", "city", "pin", "country", "phone", "add"].map((field) => (
                        <Grid item xs={12} sm={6} key={field}>
                            <TextField
                                fullWidth
                                label={field.charAt(0).toUpperCase() + field.slice(1)}
                                name={field}
                                value={formData.pickup_location[field] || ""}
                                onChange={(e) => handleChange(e, "pickup_location")}
                            />
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Shipment Type</FormLabel>
                            <RadioGroup
                                row
                                value={shipmentType}
                                onChange={handleShipmentTypeChange}
                            >
                                <FormControlLabel
                                    value="Forward Shipment"
                                    control={<Radio />}
                                    label="Forward Shipment"
                                />
                                <FormControlLabel
                                    value="Reverse Shipment"
                                    control={<Radio />}
                                    label="Reverse Shipment"
                                />
                                <FormControlLabel
                                    value="REPL Shipment"
                                    control={<Radio />}
                                    label="REPL Shipment"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">Shipment Details</Typography>
                    </Grid>
                    {[
                        "add",
                        "phone",
                        "name",
                        "country",
                        "pin",
                        "order",
                        "waybill",
                    ].map((field) => (
                        <Grid item xs={12} sm={6} key={field}>
                            <TextField
                                fullWidth
                                label={field.charAt(0).toUpperCase() + field.slice(1).replace("_", " ")}
                                name={field}
                                value={formData.shipments[0][field] || ""}
                                onChange={(e) => handleChange(e, "shipments")}
                                type={field === "cod_amount" ? "number" : "text"}
                            />
                        </Grid>
                    ))}

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Payment Mode</InputLabel>
                            <Select
                                name="payment_mode"
                                value={formData.shipments[0].payment_mode || ""}
                                onChange={(e) => handleChange(e, "shipments")}
                            >
                                {paymentModeOptions[shipmentType]?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {formData.shipments[0]?.payment_mode === "COD" && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="COD Amount"
                                name="cod_amount"
                                type="number"
                                value={formData.shipments[0]?.cod_amount || ""} // Prevents default fallback to 0
                                onChange={(e) => handleChange(e, "shipments")}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Address Type</InputLabel>
                            <Select
                                name="address_type"
                                value={formData.shipments[0].address_type || ""}
                                onChange={(e) => handleChange(e, "shipments")}
                            >
                                {addressTypeOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Shipping Mode</InputLabel>
                            <Select
                                name="shipping_mode"
                                value={formData.shipments[0].shipping_mode || ""}
                                onChange={(e) => handleChange(e, "shipments")}
                            >
                                {shippingModeOptions.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="fragile_shipment"
                                    checked={formData.shipments[0].fragile_shipment}
                                    onChange={(e) => handleChange(e, "shipments")}
                                />
                            }
                            label="Fragile Shipment"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6">GST Details</Typography>
                    </Grid>
                    {["seller_gst_tin", "client_gst_tin", "invoice_reference"].map((gstField) => (
                        <Grid item xs={12} sm={6} key={gstField}>
                            <TextField
                                fullWidth
                                label={gstField.replace("_", " ").toUpperCase()}
                                name={`gst_details.${gstField}`}
                                value={formData.shipments[0].gst_details[gstField] || ""}
                                onChange={(e) => handleChange(e, `gst_details.${gstField}`)}
                            />
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="HSN Code"
                            name="gst_details.hsn_code"
                            value={formData.shipments[0].gst_details.hsn_code.join(", ")}
                            onChange={(e) =>
                                handleChange(
                                    { target: { value: e.target.value.split(",") } },
                                    `gst_details.hsn_code`
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Create Order"}
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
                    Order created successfully!
                </Alert>
            </Snackbar>

            {error && <Alert severity="error">{error}</Alert>}
            {result && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                </Alert>
            )}
        </Container>
    );
};

export default CreateOrderForm;