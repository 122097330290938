import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

import ProductItem from "../../ProductItem";
import { fetchDataFromApi } from "../../../utils/api";
import "./TrendingProducts.css";

const TrendingProducts = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    const location = localStorage.getItem("location");

    if (location !== null && location !== "" && location !== undefined) {
      fetchDataFromApi(`/api/products/featured?location=${location}`).then(
        (res) => {
          setFeaturedProducts(res);
        }
      );
    }
  }, []);

  return (
    <div className="container mt-5 trending-products">
      <div className="d-flex align-items-center">
        <div className="info">
          <h3 className="hd mb-2">Trending Products</h3>
          <p className="text-light text-sml">
            Do not miss the current offers until the end of this month.
          </p>
        </div>
      </div>

      <div className="w-100 mt-2">
        <Swiper
          slidesPerView={1}
          spaceBetween={15}
          navigation={true}
          loop={true}
          centeredSlides={false}
          modules={[Navigation, Autoplay]}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          breakpoints={{
            200: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
        >
          {featuredProducts?.length !== 0 &&
            featuredProducts
              ?.slice(0)
              ?.reverse()
              ?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <ProductItem item={item} />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
    </div>
  );
};

export default TrendingProducts;
