import Rating from "@mui/material/Rating";
import { TfiFullscreen } from "react-icons/tfi";
import Button from "@mui/material/Button";
import { IoMdHeartEmpty } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "../../context/SnackbarProvider";
import ProductModal from "../ProductModal";
import Slider from "react-slick";
import Skeleton from "@mui/material/Skeleton";
import { IoIosImages } from "react-icons/io";
import { fetchDataFromApi, postData } from "../../utils/api";
import { FaHeart } from "react-icons/fa";
import { Card, Box } from "@mui/material";

import "./style.css";
import { red } from "@mui/material/colors";

const ProductItem = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);
  const [productData, setProductData] = useState([]);
  const [isOpenProductModal, setisOpenProductModal] = useState(false);
  const { showSnackbar } = useSnackbar();

  const sliderRef = useRef();

  var settings = {
    dots: true,
    infinite: true,
    loop: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: 100,
  };

  const openProductDetailsModal = (id, status) => {
    fetchDataFromApi(`/api/products/${id}`).then((res) => {
      setProductData(res);
      setisOpenProductModal(status);
    });
  };

  const viewProductDetails = (id) => {
    openProductDetailsModal(id, true);
  };

  const handleMouseEnter = (id) => {
    if (isLoading === false) {
      setIsHovered(true);
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickPlay();
        }
      }, 20);
    }

    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`).then((res) => {
      if (res.length !== 0) {
        setSsAddedToMyList(true);
      }
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleMouseLeave = () => {
    if (isLoading === false) {
      setIsHovered(false);
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickPause();
        }
      }, 20);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const addToMyList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined && user !== null && user !== "") {
      const data = {
        productTitle: props?.item?.name,
        image: props.item?.images[0],
        rating: props?.item?.rating,
        price: props?.item?.price,
        productId: id,
        userId: user?.userId,
      };
      postData(`/api/my-list/add/`, data).then((res) => {
        if (res.status !== false) {
          showSnackbar("The product added in my list", "success", "#aadbaa");

          fetchDataFromApi(`/api/my-list?productId=${id}&userId=${user?.userId}`).then((res) => {
            if (res.length !== 0) {
              setSsAddedToMyList(true);
            }
          }).catch((error) => {
            const msg = error.message || "Internal srever error!!"
            // showSnackbar(msg, "error", "#f1b9b9");
          });
        } else {
          showSnackbar(res.msg, "error", "#f1b9b9");
        }
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });

    } else {
      showSnackbar("Please Login to continue", "error", "#f1b9b9");
    }
  };

  return (
    <>
      <Card
        className={`productItem ${props.itemView}`}
        onMouseEnter={() =>
          handleMouseEnter(
            props?.itemView === "recentlyView"
              ? props.item?.prodId
              : props.item?.id
          )
        }
        onMouseLeave={handleMouseLeave}
        sx={{
          "&:hover": {
            boxShadow: 4,
          },
          boxShadow: 2,
        }}
      >
        <Box className="img_rapper">
          <Link
            to={`/product/${props?.itemView === "recentlyView"
                ? props.item?.prodId
                : props.item?.id
              }`}
          >
            <Box className="productItemSliderWrapper">
              {isHovered === true && (
                <Slider
                  {...settings}
                  ref={sliderRef}
                  className="productItemSlider"
                >
                  {props.item?.images?.map((image, index) => {
                    return (
                      <Box className="slick-slide" key={index}>
                        <img src={image} />
                      </Box>
                    );
                  })}
                </Slider>
              )}
            </Box>

            {isLoading === true ? (
              <Skeleton variant="rectangular" width={300} height={400}>
                <IoIosImages />
              </Skeleton>
            ) : (
              <img src={props.item?.images[0]} className="w-100" />
            )}
          </Link>

          <span className="badge">{props.item?.discount}%</span>

          <Box className="actions">
            <Button
              onClick={() =>
                viewProductDetails(
                  props?.itemView === "recentlyView"
                    ? props.item?.prodId
                    : props.item?.id
                )
              }
            >
              <TfiFullscreen />
            </Button>

            <Button
              className={isAddedToMyList === true && "active"}
              onClick={() =>
                addToMyList(
                  props?.itemView === "recentlyView"
                    ? props.item?.prodId
                    : props.item?.id
                )
              }
            >
              {isAddedToMyList === true ? (
                <FaHeart style={{ fontSize: "20px" }} />
              ) : (
                <IoMdHeartEmpty style={{ fontSize: "20px" }} />
              )}
            </Button>
          </Box>
        </Box>

        <Box className="info">
          <Link
            to={`/product/${props?.itemView === "recentlyView"
                ? props.item?.prodId
                : props.item?.id
              }`}
          >
            <h4>{props?.item?.name?.substr(0, 30) + "..."}</h4>
          </Link>

          {props?.item?.countInStock >= 1 ? props.item.countInStock > 5 ? (
            <span className="text-success d-block">In Stock</span>
          ) :
            (
              <span className="text-danger d-block"  >Only {props.item.countInStock} item left !! </span>
            ) : (
            <span className="text-danger d-block">Out of Stock</span>
          )}

          <Rating
            className="mt-2 mb-2"
            name="read-only"
            value={props?.item?.rating}
            readOnly
            size="small"
            precision={0.5}
          />

          <Box className="d-flex">
            <span className="oldPrice">Rs {props?.item?.oldPrice}</span>
            <span className="netPrice text-danger ml-2">
              Rs {props?.item?.price}
            </span>
          </Box>
        </Box>
      </Card>
      {isOpenProductModal === true && (
        <ProductModal
          data={productData}
          isOpenProductModal={isOpenProductModal}
          setisOpenProductModal={setisOpenProductModal}
        />
      )}
    </>
  );
};

export default ProductItem;
