import React, { useEffect, useState, useRef } from "react";
import audio from "../../../audio/audio.mp3";
import { io } from "socket.io-client";

import DashboardBox from "./sub-pages/dashboardBox";
import { FaUserCircle } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { MdShoppingBag } from "react-icons/md";
import { GiStarsStack } from "react-icons/gi";
import MenuItem from "@mui/material/MenuItem";
import { Chart } from "react-google-charts";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Rating from "@mui/material/Rating";
import { deleteData, fetchDataFromApi } from "../../../utils/api";
import { useSnackbar } from "../../../context/SnackbarProvider";
import { useNavigate } from "react-router-dom";
import "./style.css";

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Switch,
  TablePagination,
  styled,
  tableCellClasses,
} from "@mui/material";

// Custom StyledTableCell and StyledTableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primaryColor)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const data = [
  ["Year", "Sales", "Expenses"],
  ["2013", 1000, 400],
  ["2014", 1170, 460],
  ["2015", 660, 1120],
  ["2016", 1030, 540],
];

export const options = {
  backgroundColor: "transparent",
  chartArea: { width: "100%", height: "100%" },
};

const Dashboard = () => {
  const audioRef = useRef(new Audio(audio));
  const socket = useRef(null);

  const [showBy, setshowBy] = useState(8);
  const [productList, setProductList] = useState([]);
  const [catData, setCatData] = useState([]);
  const [categoryVal, setcategoryVal] = useState("all");
  const { showSnackbar } = useSnackbar();
  const [totalUsers, setTotalUsers] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [totalProductsReviews, setTotalProductsReviews] = useState();
  const [totalSales, setTotalSales] = useState();

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();

  useEffect(() => {
    // Initialize socket connection
    socket.current = io(process.env.REACT_APP_API_URL);
    socket.current.on('newOrder', (order) => {
      console.log("New order received:", order);
      // setOrders((prevOrders) => [...prevOrders, order]);
      setTotalOrders((prevCount) => prevCount + 1);
      playSound();
      showSnackbar("New order received!", "success", "#aadbaa");
    });

    // Clean up on component unmount
    return () => {
      socket.current.disconnect();
    };
  }, []);


  const playSound = () => {
    audioRef.current.play();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!token || !user || user.role !== "admin") {
      showSnackbar("Forbidden: Insufficient Permissions.", "error", "#f1b9b9");
      history("/");
    }

    fetchDataFromApi("/api/products?page=1&perPage=8").then((res) => {
      setProductList(res);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    fetchDataFromApi("/api/user/get/count").then((res) => {
      setTotalUsers(res.userCount);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    fetchDataFromApi("/api/orders/get/count").then((res) => {
      setTotalOrders(res.orderCount);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    let sales = 0;
    fetchDataFromApi("/api/orders/")
      .then((res) => {
        res?.length !== 0 &&
          res?.map((item) => {
            sales += parseInt(item.amount);
          });
        setTotalSales(sales);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });

    fetchDataFromApi("/api/products/get/count").then((res) => {
      setTotalProducts(res.productsCount);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    fetchDataFromApi("/api/productReviews/get/count").then((res) => {
      setTotalProductsReviews(res.productsReviews);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    fetchDataFromApi("/api/category").then((res) => {
      setCatData(res);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  }, []);

  const deleteProduct = (id) => {
    deleteData(`/api/products/${id}`).then((res) => {
      showSnackbar("Product Deleted!", "success", "#aadbaa");
      fetchDataFromApi("/api/products?page=${1}&perPage=8").then((res) => {
        setProductList(res);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleChange = (event, value) => {
    fetchDataFromApi(`/api/products?page=${value}&perPage=8`).then((res) => {
      setProductList(res);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

  };

  const showPerPage = (e) => {
    setshowBy(e.target.value);
    fetchDataFromApi(`/api/products?page=${1}&perPage=${e.target.value}`).then(
      (res) => {
        setProductList(res);
      }
    );
  };

  const handleChangeCategory = (event) => {
    if (event.target.value !== "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?category=${event.target.value}`).then((res) => {
        setProductList(res);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    }
    if (event.target.value === "all") {
      setcategoryVal(event.target.value);
      fetchDataFromApi(`/api/products?page=${1}&perPage=${8}`).then((res) => {
        setProductList(res);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    }
  };

  return (
    <>
      <div className="w-100">
        <div className="dashboardBoxWrapperRow dashboard_Box dashboardBoxWrapperRowV2">
          <div className="dashboardBoxWrapper">
            <DashboardBox
              color={["#1da256", "#48d483"]}
              icon={<FaUserCircle />}
              grow={true}
              title="Total Users"
              count={totalUsers}
            />
            <DashboardBox
              color={["#c012e2", "#eb64fe"]}
              icon={<IoMdCart />}
              title="Total Orders"
              count={totalOrders}
            />
            <DashboardBox
              color={["#2c78e5", "#60aff5"]}
              icon={<MdShoppingBag />}
              title="Total Products"
              count={totalProducts}
            />
            <DashboardBox
              color={["#e1950e", "#f3cd29"]}
              icon={<GiStarsStack />}
              title="Total Reviews"
              count={totalProductsReviews}
            />
          </div>
        </div>

        <div className="border-0 mt-5">
          <h3 className="hd">Best Selling Products</h3>

          <div className="row cardFilters">
            <div className="col-md-3 mt-3">
              <h4>SHOW BY</h4>
              <FormControl size="small" className="w-100">
                <Select
                  value={showBy}
                  onChange={showPerPage}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  labelId="demo-select-small-label"
                  className="w-100"
                >
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={70}>70</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-md-3 mt-3">
              <h4>CATEGORY BY</h4>
              <FormControl size="small" className="w-100">
                <Select
                  value={categoryVal}
                  onChange={handleChangeCategory}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="w-100"
                >
                  <MenuItem value="all">
                    <em value={"all"}>All</em>
                  </MenuItem>
                  {catData?.categoryList?.length !== 0 &&
                    catData?.categoryList?.map((cat, index) => {
                      return (
                        <MenuItem
                          className="text-capitalize"
                          value={cat._id}
                          key={index}
                        >
                          {cat.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          </div>

          <Card
            sx={{
              overflowX: "auto",
              borderRadius: 2,
              boxShadow: 1,
              mt: 3,
            }}
            className="table-responsive"
          >
            <table className="table table-striped v-align">
              <thead className="thead-dark">
                <tr>
                  <th style={{ width: "300px" }}>PRODUCT</th>
                  <th>CATEGORY</th>
                  <th>BRAND</th>
                  <th>PRICE</th>
                  <th>RATING</th>
                  <th>ACTION</th>
                </tr>
              </thead>

              <tbody>
                {productList?.products?.length !== 0 &&
                  productList?.products?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center productBox">
                            <div className="imgWrapper">
                              <Card
                                className="img m-0"
                                sx={{ border: "1px solid var(--borderLight)" }}
                              >
                                <LazyLoadImage
                                  alt={"image"}
                                  effect="blur"
                                  className="w-100"
                                  src={item.images[0]}
                                />
                              </Card>
                            </div>
                            <div className="info pl-3">
                              <h6>{item?.name}</h6>
                              <p>{item?.description}</p>
                            </div>
                          </div>
                        </td>
                        <td>{item?.category?.name}</td>
                        <td>{item?.brand}</td>
                        <td>
                          <div style={{ width: "70px" }}>
                            <del className="old">Rs {item?.oldPrice}</del>
                            <span className="new text-danger">
                              Rs {item?.price}
                            </span>
                          </div>
                        </td>
                        <td>
                          <Rating
                            name="read-only"
                            defaultValue={item?.rating}
                            precision={0.5}
                            size="small"
                            readOnly
                          />
                        </td>
                        <td>
                          <div className="actions d-flex align-items-center">
                            <Link to={`/admin/product/details/${item.id}`}>
                              <Button className="secondary" color="secondary">
                                <FaEye />
                              </Button>
                            </Link>

                            <Link to={`/admin/product/edit/${item.id}`}>
                              <Button className="success" color="success">
                                <FaPencilAlt />
                              </Button>
                            </Link>

                            <Button
                              className="error"
                              color="error"
                              onClick={() => deleteProduct(item?.id)}
                            >
                              <MdDelete />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {productList?.totalPages > 1 && (
              <div className="d-flex tableFooter">
                <Pagination
                  count={productList?.totalPages}
                  color="primary"
                  showFirstButton
                  showLastButton
                  onChange={handleChange}
                />
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
