import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import Button from "@mui/material/Button";
import {
  deleteImages,
  postData,
  uploadImage,
} from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { FaRegImages } from "react-icons/fa";
import { useSnackbar } from "../../../../context/SnackbarProvider";

import CircularProgress from "@mui/material/CircularProgress";
import { IoCloseSharp } from "react-icons/io5";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

//breadcrumb code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const AddCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [formFields, setFormFields] = useState({
    name: "",
    images: "",
   
    slug: "",
    parentId: "",
  });

  const [previews, setPreviews] = useState();

  const formdata = new FormData();

  const history = useNavigate();

  const { showSnackbar } = useSnackbar();

  const changeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeFile = async (e, apiEndPoint) => {
    try {
      const file = e.target.files ? e.target.files[0] : null;

      if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp")) {
        setUploading(true);

        formdata.append("images", file);

        uploadImage(apiEndPoint, formdata)
          .then((res) => {
            setUploading(false);
            showSnackbar("Category Image Uploaded Successfully!!", "success", "#aadbaa");
            setPreviews(res);
          })
          .catch((error) => {
            const msg = error.message || "Internal srever error!!"
            // showSnackbar(msg, "error", "#f1b9b9");
          });

      } else {
        showSnackbar("Please select a valid JPG or PNG image file.", "error", "#f1b9b9");
        setUploading(false);
      }
    } catch (error) {
      showSnackbar("Image upload error ", "error", "#f1b9b9");
    }
  };

  const removeImg = async (imgUrl) => {
    deleteImages(`/api/category/deleteImage?img=${imgUrl}`).then((res) => {
      setPreviews(null)
      showSnackbar("Image Deleted!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const addCat = (e) => {
    e.preventDefault();

    formFields.slug = formFields.name;
    formFields.images = previews;

    if (
      formFields.name !== "" &&
 
      formFields.images !== ""
    ) {
      setIsLoading(true);

      postData("/api/category/create", formFields).then((res) => {
        setIsLoading(false);
        showSnackbar("Category successfully added!!", "success", "#aadbaa");
        history("/admin/category");
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    } else {
      showSnackbar("Please fill all the details", "error", "#f1b9b9");
      return false;
    }
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
          <h5 className="mb-0">Add Category</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Dashboard"
              icon={<HomeIcon fontSize="small" />}
            />

            <StyledBreadcrumb
              component="a"
              label="Category"
              href="#"
              deleteIcon={<ExpandMoreIcon />}
            />
            <StyledBreadcrumb
              label="Add Category"
              deleteIcon={<ExpandMoreIcon />}
            />
          </Breadcrumbs>
        </div>

        <form className="form" onSubmit={addCat}>
          <div className="row">
            <div className="col-sm-9">
              <div className="card p-4 mt-0">
                <div className="form-group">
                  <h6>Category Name</h6>
                  <input
                    type="text"
                    name="name"
                    value={formFields.name}
                    onChange={changeInput}
                  />
                </div>

              

                <div className="imagesUploadSec">
                  <h5 className="mb-4">Media And Published</h5>

                  <div className="imgUploadBox d-flex align-items-center">
                    {
                      previews &&
                      <div className="uploadBox">
                        <span
                          className="remove"
                          onClick={() => removeImg(previews)}
                        >
                          <IoCloseSharp />
                        </span>
                        <div className="box">
                          <LazyLoadImage
                            alt={"image"}
                            effect="blur"
                            className="w-100"
                            src={previews}
                          />
                        </div>
                      </div>
                    }
                    {
                      !previews && (
                        <div className="uploadBox">
                          {uploading === true ? (
                            <div className="progressBar text-center d-flex align-items-center justify-content-center flex-column">
                              <CircularProgress />
                              <span>Uploading...</span>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                multiple
                                onChange={(e) =>
                                  onChangeFile(e, "/api/images/upload")
                                }
                                name="images"
                              />
                              <div className="info">
                                <FaRegImages />
                                <h5>image upload</h5>
                              </div>
                            </>
                          )}
                        </div>
                      )
                    }
                  </div>

                  <br />

                  <Button
                    type="submit"
                    className="btn-blue btn-lg btn-big w-100"
                  >
                    <FaCloudUploadAlt /> &nbsp;{" "}
                    {isLoading === true ? (
                      <CircularProgress color="inherit" className="loader" />
                    ) : (
                      "PUBLISH AND VIEW"
                    )}{" "}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddCategory;