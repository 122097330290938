import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import Button from "@mui/material/Button";
import {
  deleteData,
  fetchDataFromApi,
  postData,
} from "../../../../utils/api";

import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../context/SnackbarProvider";
import CircularProgress from "@mui/material/CircularProgress";
import "react-lazy-load-image-component/src/effects/blur.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

//breadcrumb code
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const AddSubCat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [catData, setCatData] = useState([]);
  const [categoryVal, setcategoryVal] = useState("");
  const [formFields, setFormFields] = useState({
    name: "",
    slug: "",
    parentId: "",
  });

  const history = useNavigate();

  useEffect(() => {
    fetchDataFromApi("/api/category").then((res) => {
      setCatData(res);
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  }, []);

  const changeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeCategory = (event) => {
    setcategoryVal(event.target.value);
  };

  const selectCat = (cat, id) => {
    formFields.parentId = id;
  };

  const addSubCategory = (e) => {
    e.preventDefault();

    formFields.slug = formFields.name;

    if (formFields.name !== "" && formFields.parentId !== "") {
      setIsLoading(true);

      postData(`/api/category/create`, formFields).then((res) => {
        setIsLoading(false);
        showSnackbar("SubCategory Added Successfully!!", "success", "#aadbaa");
        history("/admin/subCategory");
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    } else {
      showSnackbar("Please fill all the details", "error", "#f1b9b9");
      return false;
    }
  };

  return (
    <>
      <div className="right-content w-100">
        <div className="card shadow border-0 w-100 flex-row p-4 mt-2">
          <h5 className="mb-0">Add Category</h5>
          <Breadcrumbs aria-label="breadcrumb" className="ml-auto breadcrumbs_">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="Dashboard"
              icon={<HomeIcon fontSize="small" />}
            />

            <StyledBreadcrumb
              component="a"
              label="Category"
              href="#"
              deleteIcon={<ExpandMoreIcon />}
            />
            <StyledBreadcrumb
              label="Add Category"
              deleteIcon={<ExpandMoreIcon />}
            />
          </Breadcrumbs>
        </div>

        <form className="form" onSubmit={addSubCategory}>
          <div className="row">
            <div className="col-sm-9">
              <div className="card p-4 mt-0">
                <div className="form-group">
                  <h6>Parent Category</h6>
                  <Select
                    value={categoryVal}
                    onChange={handleChangeCategory}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="w-100"
                  >
                    <MenuItem value="">
                      <em value={null}>None</em>
                    </MenuItem>
                    {catData?.categoryList?.length !== 0 &&
                      catData?.categoryList?.map((cat, index) => {
                        return (
                          <MenuItem
                            className="text-capitalize"
                            value={cat._id}
                            key={index}
                            onClick={() => selectCat(cat.name, cat._id)}
                          >
                            {cat.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>

                <div className="form-group">
                  <h6>Sub Category</h6>
                  <input
                    type="text"
                    name="name"
                    value={formFields.name}
                    onChange={changeInput}
                  />
                </div>

                <br />

                <Button type="submit" className="btn-blue btn-lg btn-big w-100">
                  <FaCloudUploadAlt /> &nbsp;{" "}
                  {isLoading === true ? (
                    <CircularProgress color="inherit" className="loader" />
                  ) : (
                    "PUBLISH AND VIEW"
                  )}{" "}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddSubCat;
