import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import AdminProfilePopper from "./AdminProfilePopper";
import profileIcon from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";

const AdminAppBar = ({ handleDrawerToggle, drawerWidth }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const history = useNavigate();

  const handleAvatarClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const open = Boolean(anchorEl);

  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "var(--bgLight)",
          color: "var(--textColor)",
          boxShadow: "none",
          borderBottom: "1px solid var(--borderColor)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" }, color: "var(--textColor)" }}
          >
            <MenuIcon />
          </IconButton>

          <div style={{ flexGrow: 1 }} />

          <IconButton
            edge="end"
            color="primary"
            sx={{ color: "var(--textColor)" }}
            onClick={handleAvatarClick}
          >
            <Avatar
              alt={"User Profile"}
              src={user.avatar || profileIcon}
              sx={{ color: "white", background: "var(--primaryColor)" }}
            />
          </IconButton>

          {/* Profile Popper */}
          <AdminProfilePopper
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            userName={user.name || "User Name"}
            userEmail={user.email || "user@example.com"}
            userImage={user.avatar || profileIcon}
            handleLogout={async () => {
              setAnchorEl(null);
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              localStorage.removeItem("location");
              history("/signIn");
            }}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AdminAppBar;
