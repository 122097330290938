import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Chip,
  Grid,
  Paper,
  Divider,
  Input,
} from "@mui/material";

import {
  deleteData,
  deleteImages,
  editData,
  fetchDataFromApi,
  postData,
  uploadImage,
} from "../../../../../utils/api";
import { useSnackbar } from "../../../../../context/SnackbarProvider";
import { Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import { IoCloseSharp } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaRegImages } from "react-icons/fa";

const EditPrivacyPolicy = () => {
  const { id } = useParams(); // Get id from URL parameters
  const navigate = useNavigate(); // For navigation
  const { showSnackbar } = useSnackbar();

  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");
  const [slug, setSlug] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [sections, setSections] = useState([
    { title: "", description: "", points: [{ title: "", description: "" }] },
  ]);
  const [providerName, setProviderName] = useState("");
  const [providerWebsite, setProviderWebsite] = useState("");
  const [providerLogo, setProviderLogo] = useState(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Fetch the privacy policy data
  useEffect(() => {
    const fetchPrivacyPolicy = async () => {

      fetchDataFromApi(`/api/privacy-policy/${id}`).then((res) => {
        const policy = res;
        setTitle(policy.title);
        setVersion(policy.version);
        setSlug(policy.slug);
        setMetaTitle(policy.metaData.title);
        setMetaDescription(policy.metaData.description);
        setMetaKeywords(policy.metaData.keywords || []);
        setSections(policy.sections || []);
        setProviderName(policy.provider.name);
        setProviderWebsite(policy.provider.website);
        setDescription(policy.description || "");
        setProviderLogo(policy.provider.logo);
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    };

    if (id) {
      fetchPrivacyPolicy();
    }
  }, [id]);

  // Handling Keywords
  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      setMetaKeywords([...metaKeywords, newKeyword.trim()]);
      setNewKeyword("");
    }
  };

  const handleRemoveKeyword = (index) => {
    setMetaKeywords((prev) => prev.filter((_, i) => i !== index));
  };

  // Handling Sections
  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index] = { ...updatedSections[index], [field]: value };
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    setSections((prev) => [
      ...prev,
      { title: "", description: "", points: [{ title: "", description: "" }] },
    ]);
  };

  const handleRemoveSection = (index) => {
    setSections((prev) => prev.filter((_, i) => i !== index));
  };

  // Handling Points within Sections
  const handlePointChange = (sectionIndex, pointIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points[pointIndex] = {
      ...updatedSections[sectionIndex].points[pointIndex],
      [field]: value,
    };
    setSections(updatedSections);
  };

  const handleAddPoint = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points.push({ title: "", description: "" });
    setSections(updatedSections);
  };

  const handleRemovePoint = (sectionIndex, pointIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points = updatedSections[sectionIndex].points.filter((_, i) => i !== pointIndex);
    setSections(updatedSections);
  };

  const onChangeFile = async (e, apiEndPoint) => {
    const formdata = new FormData();
    try {
      const file = e.target.files ? e.target.files[0] : null;
      setUploading(true);

      if (
        file &&
        (file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/webp")
      ) {
        formdata.append('images', file);

      } else {
        showSnackbar("Please select a valid JPG or PNG image file.", "error", "#f1b9b9");
        return false;
      }
    } catch (error) {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    }

    uploadImage(apiEndPoint, formdata).then((res) => {
      setProviderLogo(res)
      setUploading(false);
      showSnackbar("Policy Image Uploaded Successfully!!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  // Form submission for updating the policy
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      title,
      version,
      slug,
      metaData: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
      },
      sections,
      provider: {
        name: providerName,
        website: providerWebsite,
        logo: providerLogo,
      },
      description,
    };

    editData(`/api/privacy-policy/${id}`, payload).then((res) => {
      showSnackbar("Policy Uploaded Successfully!!", "success", "#aadbaa");
      navigate('/admin/legal/privacy-policy')
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    setLoading(false);
  };

  const removeImg = async (imgUrl) => {
    deleteImages(`/api/privacy-policy/deleteImage?img=${imgUrl}`).then((res) => {
      showSnackbar("Image deleted Successfully!!", "success", "#aadbaa");
      setProviderLogo(null)
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "1rem",
          fontWeight: "600",
          color: "var(--textHeading)",
          marginBottom: "2rem",
        }}
      >
        Dashboard / Legal / Edit Privacy Policy
      </Typography>
      <form onSubmit={handleSubmit}>
        <Paper
          sx={{
            p: 2,
            mb: 2,
            borderRadius: 2,
            border: "1px solid var(--borderCool)",
            background: "var(--bgLight)",
          }}
        >
          <Grid container spacing={2}>
            {/* Title, Version, Slug */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Version"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                margin="normal"
                required
              />
            </Grid>
          </Grid>
        </Paper>

        <Box>
          <Divider
            sx={{
              height: "2px",
              background: "var(--borderCool)",
              opacity: "0.5",
              margin: "2rem 0",
            }}
          />
        </Box>

        {/* Meta Data */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            color: "var(--textColor)",
            margin: "1.5rem 0",
          }}
        >
          Meta Data
        </Typography>
        <Paper
          sx={{
            p: 2,
            mb: 2,
            borderRadius: 2,
            border: "1px solid var(--borderCool)",
            background: "var(--bgLight)",
          }}
        >
          <TextField
            fullWidth
            label="Meta Title"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Meta Description"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            margin="normal"
            multiline
            rows={3}
            required
          />
          <Box>
            <Box
              sx={{ display: "flex", gap: "1rem", alignItems: "center", mb: 2 }}
            >
              <TextField
                label="Add Keyword"
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
              />
              <IconButton
                color="primary"
                onClick={handleAddKeyword}
                disabled={!newKeyword.trim()}
              >
                <Add />
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {metaKeywords.map((keyword, index) => (
                <Chip
                  key={index}
                  label={keyword}
                  onDelete={() => handleRemoveKeyword(index)}
                  sx={{ margin: "0.25rem" }}
                />
              ))}
            </Box>
          </Box>
        </Paper>

        {/* Sections */}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            color: "var(--textColor)",
            margin: "1.5rem 0",
          }}
        >
          Sections
        </Typography>
        {sections.map((section, sectionIndex) => (
          <Paper
            key={sectionIndex}
            sx={{
              p: 2,
              mb: 2,
              borderRadius: 2,
              border: "1px solid var(--borderCool)",
              background: "var(--bgLight)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Section Title"
                  value={section.title}
                  onChange={(e) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Section Description"
                  value={section.description}
                  onChange={(e) => handleSectionChange(sectionIndex, 'description', e.target.value)}
                  margin="normal"
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
            <Box>
              {section.points.map((point, pointIndex) => (
                <Box key={pointIndex} sx={{ mt: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Point Title"
                        value={point.title}
                        onChange={(e) => handlePointChange(sectionIndex, pointIndex, 'title', e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Point Description"
                        value={point.description}
                        onChange={(e) => handlePointChange(sectionIndex, pointIndex, 'description', e.target.value)}
                        margin="normal"
                        multiline
                        rows={2}
                      />
                    </Grid>
                  </Grid>
                  <IconButton onClick={() => handleRemovePoint(sectionIndex, pointIndex)} color="error">
                    <Remove />
                  </IconButton>
                </Box>
              ))}
              <Button variant="outlined" onClick={() => handleAddPoint(sectionIndex)}>
                Add Point
              </Button>
            </Box>
            <IconButton onClick={() => handleRemoveSection(sectionIndex)} color="error">
              <RiDeleteBin5Line />
            </IconButton>
          </Paper>
        ))}
        <Button variant="outlined" onClick={handleAddSection}>
          Add Section
        </Button>

        {/* Provider Information */}
        <Paper
          sx={{
            p: 2,
            mb: 2,
            borderRadius: 2,
            border: "1px solid var(--borderCool)",
            background: "var(--bgLight)",
          }}
        >
          <Typography variant="h6">Provider Information</Typography>
          <TextField
            fullWidth
            label="Provider Name"
            value={providerName}
            onChange={(e) => setProviderName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Provider Website"
            value={providerWebsite}
            onChange={(e) => setProviderWebsite(e.target.value)}
            margin="normal"
            required
          />

          <Grid item xs={12} md={6}>
            <div className="imgUploadBox d-flex align-items-center">
              {
                providerLogo &&
                <div className="uploadBox">
                  <span
                    className="remove"
                    onClick={() => removeImg(providerLogo)}
                  >
                    <IoCloseSharp />
                  </span>
                  <div className="box">
                    <LazyLoadImage
                      alt={"image"}
                      effect="blur"
                      className="w-100"
                      src={providerLogo}
                    />
                  </div>
                </div>
              }
              {
                !providerLogo && (
                  <div className="uploadBox">
                    {uploading === true ? (
                      <div className="progressBar text-center d-flex align-items-center justify-content-center flex-column">
                        <CircularProgress />
                        <span>Uploading...</span>
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          multiple
                          onChange={(e) =>
                            onChangeFile(e, "/api/images/upload")
                          }
                          name="images"
                        />
                        <div className="info">
                          <FaRegImages />
                          <h5>image upload</h5>
                        </div>
                      </>
                    )}
                  </div>
                )
              }
            </div>
          </Grid>

        </Paper>

        {/* Privacy Policy Description */}
        <Paper
          sx={{
            p: 2,
            mb: 2,
            borderRadius: 2,
            border: "1px solid var(--borderCool)",
            background: "var(--bgLight)",
          }}
        >
          <Typography variant="h6">Description</Typography>
          <TextField
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={4}
            required
          />
        </Paper>

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </form>
    </Box>
  );
};

export default EditPrivacyPolicy;
