import React, { useState } from 'react';
import { fetchDataFromApi, postData } from '../../utils/api';
import axios from 'axios';

const FetchWaybill = () => {
    const [waybill, setWaybill] = useState(null);

    const handleFetchWaybill = async () => {
        await fetchDataFromApi(`/api/delhivery/fetch-waybill`)
            .then((res) => {
                setWaybill(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <h2>Fetch Waybill</h2>
            <button onClick={handleFetchWaybill}>Fetch Waybill</button>
            {waybill && <pre>{JSON.stringify(waybill, null, 2)}</pre>}
        </div>
    );
};

export default FetchWaybill;
