"use client";

import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import {
  deleteData,
  deleteImages,
  editData,
  fetchDataFromApi,
  postData,
  uploadImage,
} from "../../../../../utils/api";
import { Add as AddIcon } from "@mui/icons-material";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { useSnackbar } from "../../../../../context/SnackbarProvider";
const CreateTermsAndConditionsPage = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  // Form state
  const [title, setTitle] = useState("");
  const [version, setVersion] = useState("");
  const [slug, setSlug] = useState("");
  const [sections, setSections] = useState([
    { title: "", description: "", points: [{ title: "", description: "" }] },
  ]);
  const [services, setServices] = useState([
    { title: "", description: "", points: [{ title: "", description: "" }] },
  ]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [description, setDescription] = useState("");
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  // Section Handlers
  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index] = { ...updatedSections[index], [field]: value };
    setSections(updatedSections);
  };

  const handleSectionPointChange = (sectionIndex, pointIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points[pointIndex] = {
      ...updatedSections[sectionIndex].points[pointIndex],
      [field]: value,
    };
    setSections(updatedSections);
  };

  const handleAddSection = () => {
    setSections([...sections, { title: "", description: "", points: [{ title: "", description: "" }] }]);
  };

  const handleRemoveSection = (index) => {
    setSections((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddSectionPoint = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].points.push({ title: "", description: "" });
    setSections(updatedSections);
  };

  const handleRemoveSectionPoint = (sectionIndex, pointIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].points = updatedSections[sectionIndex].points.filter((_, i) => i !== pointIndex);
    setSections(updatedSections);
  };

  // Service Handlers
  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index] = { ...updatedServices[index], [field]: value };
    setServices(updatedServices);
  };

  const handleServicePointChange = (serviceIndex, pointIndex, field, value) => {
    const updatedServices = [...services];
    updatedServices[serviceIndex].points[pointIndex] = {
      ...updatedServices[serviceIndex].points[pointIndex],
      [field]: value,
    };
    setServices(updatedServices);
  };

  const handleAddService = () => {
    setServices([...services, { title: "", description: "", points: [{ title: "", description: "" }] }]);
  };

  const handleRemoveService = (index) => {
    setServices((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddServicePoint = (index) => {
    const updatedServices = [...services];
    updatedServices[index].points.push({ title: "", description: "" });
    setServices(updatedServices);
  };

  const handleRemoveServicePoint = (serviceIndex, pointIndex) => {
    const updatedServices = [...services];
    updatedServices[serviceIndex].points = updatedServices[serviceIndex].points.filter((_, i) => i !== pointIndex);
    setServices(updatedServices);
  };

  // Keyword Handlers
  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      setMetaKeywords([...metaKeywords, newKeyword.trim()]);
      setNewKeyword("");
    }
  };

  const handleRemoveKeyword = (index) => {
    setMetaKeywords((prev) => prev.filter((_, i) => i !== index));
  };

  // File Upload Handler
  const onChangeFile = async (e, apiEndPoint) => {
    const formdata = new FormData();
    const file = e.target.files ? e.target.files[0] : null;

    formdata.append('images', file);

    uploadImage(apiEndPoint, formdata).then((res) => {
      setDocument(res)
      showSnackbar("Document Uploaded Successfully", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a plain JavaScript object with the form data
    const payload = {
      title,
      version,
      slug,
      metaData: {
        title: metaTitle,
        description: metaDescription,
        keywords: metaKeywords,
      },
      sections,
      services,
      document,
      description,
    };

    try {
      postData("/api/terms-and-conditions/create", payload).then((res) => {
        showSnackbar("Terms and Conditions Added successfully", "success", "#aadbaa");
        navigate('/admin/legal/terms-and-conditions')
      }).catch((error) => {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      });
    } catch (error) {
      showSnackbar("An error accoured!!", "error", "#f1b9b9");
    } finally {
      setLoading(false);
    }
  };

  const removeImg = async (imgUrl) => {
    deleteImages(`/api/terms-and-conditions/deleteImage?img=${imgUrl}`).then((res) => {
      setDocument(null)
      showSnackbar("Image Deleted!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontSize: "1rem",
          fontWeight: "600",
          color: "var(--textHeading)",
          marginBottom: "2rem",
        }}
      >
        Dashboard / Legal / Create Terms & Conditions
      </Typography>

      <Card
        sx={{
          boxShadow: 2,
          borderRadius: "0.5rem",
          border: "1px solid var(--borderCool)",
          maxWidth: "1200px",
          background: "var(--bgLight)",
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Title, Version, Slug */}
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Version"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  label="Slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider
                  sx={{
                    height: "2px",
                    background: "var(--borderCool)",
                    opacity: "0.5",
                  }}
                />
              </Grid>

              {/* Metadata */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  Meta Data
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Meta Title"
                  value={metaTitle}
                  onChange={(e) => setMetaTitle(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Meta Description"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>

              {/* Keywords */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  Meta Keywords
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    background: "var(--bgColor)",
                  }}
                >
                  <TextField
                    label="Add Keyword"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <Button
                    variant="outlined"
                    onClick={handleAddKeyword}
                    startIcon={<AddIcon />}
                    sx={{ mt: 1, width: "220px" }}
                  >
                    Confirm & Add Keyword
                  </Button>

                  <Divider sx={{ marginTop: "1.5rem" }} />

                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {metaKeywords.map((keyword, index) => (
                      <li key={index}>
                        <Paper
                          sx={{
                            boxShadow: 0,
                            display: "flex",
                            mt: 2,
                            justifyContent: "space-between",
                            gap: "1rem",
                            fontWeight: "500",
                            background: "var(--bgColor)",
                          }}
                        >
                          {keyword}
                          <IconButton
                            size="small"
                            onClick={() => handleRemoveKeyword(index)}
                            color="error"
                            sx={{ border: "1px solid var(--borderCool)" }}
                          >
                            <MdOutlineDeleteOutline />
                          </IconButton>
                        </Paper>
                      </li>
                    ))}
                  </ul>
                </Paper>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider
                  sx={{
                    height: "2px",
                    background: "var(--borderCool)",
                    opacity: "0.5",
                  }}
                />
              </Grid>

              {/* Sections */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  Sections
                </Typography>
              </Grid>

              {sections.map((section, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "bold", my: 2 }}
                  >{`Section ${index + 1}`}</Typography>
                  <Paper
                    variant="outlined"
                    sx={{ p: 2, background: "var(--bgColor)" }}
                  >
                    <TextField
                      label="Section Title"
                      value={section.title}
                      onChange={(e) =>
                        handleSectionChange(index, "title", e.target.value)
                      }
                      fullWidth
                      required
                    />
                    <TextField
                      label="Section Description"
                      value={section.description}
                      onChange={(e) =>
                        handleSectionChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      multiline
                      rows={2}
                      fullWidth
                      sx={{ mt: 2 }}
                    />

                    {/* Points */}
                    <Typography variant="h6" sx={{ mt: 3, fontSize: "1rem" }}>
                      Points
                    </Typography>

                    {section.points.map((point, pointIndex) => (
                      <Grid
                        key={pointIndex}
                        container
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Point Title"
                            value={point.title}
                            onChange={(e) =>
                              handleSectionPointChange(
                                index,
                                pointIndex,
                                "title",
                                e.target.value
                              )
                            }
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Point Description"
                            value={point.description}
                            onChange={(e) =>
                              handleSectionPointChange(
                                index,
                                pointIndex,
                                "description",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            color="error"
                            onClick={() =>
                              handleRemoveSectionPoint(index, pointIndex)
                            }
                            startIcon={<MdOutlineDeleteOutline />}
                          >
                            Remove Point
                          </Button>
                        </Grid>
                      </Grid>
                    ))}

                    <Button
                      onClick={() => handleAddSectionPoint(index)}
                      startIcon={<AddIcon />}
                      sx={{ mt: 1 }}
                    >
                      Add Point
                    </Button>
                  </Paper>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveSection(index)}
                    startIcon={<MdOutlineDeleteOutline />}
                    sx={{ mt: 2 }}
                  >
                    Remove Section
                  </Button>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Button
                  onClick={handleAddSection}
                  startIcon={<AddIcon />}
                  sx={{ mt: 2 }}
                >
                  Add Section
                </Button>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider
                  sx={{
                    height: "2px",
                    background: "var(--borderCool)",
                    opacity: "0.5",
                  }}
                />
              </Grid>

              {/* Services */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  Services
                </Typography>
              </Grid>

              {services.map((service, serviceIndex) => (
                <Grid item xs={12} key={serviceIndex}>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: "bold", my: 2 }}
                  >{`Service ${serviceIndex + 1}`}</Typography>

                  <Paper
                    variant="outlined"
                    sx={{ p: 2, background: "var(--bgColor)" }}
                  >
                    <TextField
                      label="Service Title"
                      value={service.title}
                      onChange={(e) =>
                        handleServiceChange(
                          serviceIndex,
                          "title",
                          e.target.value
                        )
                      }
                      fullWidth
                      required
                    />

                    <TextField
                      label="Service Description"
                      value={service.description}
                      onChange={(e) =>
                        handleServiceChange(
                          serviceIndex,
                          "description",
                          e.target.value
                        )
                      }
                      fullWidth
                      multiline
                      rows={3}
                      required
                      sx={{ mt: 2 }}
                    />

                    {/* Points */}
                    <Typography variant="h6" sx={{ mt: 3, fontSize: "1rem" }}>
                      Points
                    </Typography>

                    {service.points.map((point, pointIndex) => (
                      <Grid
                        key={pointIndex}
                        container
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Point Title"
                            value={point.title}
                            onChange={(e) =>
                              handleServicePointChange(
                                serviceIndex,
                                pointIndex,
                                "title",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            label="Point Description"
                            value={point.description}
                            onChange={(e) =>
                              handleServicePointChange(
                                serviceIndex,
                                pointIndex,
                                "description",
                                e.target.value
                              )
                            }
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            color="error"
                            onClick={() =>
                              handleRemoveServicePoint(serviceIndex, pointIndex)
                            }
                            startIcon={<MdOutlineDeleteOutline />}
                          >
                            Remove Point
                          </Button>
                        </Grid>
                      </Grid>
                    ))}

                    <Button
                      onClick={() => handleAddServicePoint(serviceIndex)}
                      startIcon={<AddIcon />}
                      sx={{ mt: 1 }}
                    >
                      Add Point
                    </Button>
                  </Paper>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveService(serviceIndex)}
                    startIcon={<MdOutlineDeleteOutline />}
                    sx={{ mt: 2 }}
                  >
                    Remove Service
                  </Button>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Button
                  onClick={handleAddService}
                  startIcon={<AddIcon />}
                  sx={{ mt: 2 }}
                >
                  Add Service
                </Button>
              </Grid>

              {/* Divider */}
              <Grid item xs={12}>
                <Divider
                  sx={{
                    height: "2px",
                    background: "var(--borderCool)",
                    opacity: "0.5",
                  }}
                />
              </Grid>

              {/* Document Upload */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  Document
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" component="label">
                  Upload Document
                  <input
                    type="file"
                    hidden
                    accept=".pdf,.doc,.docx"
                    onChange={(e) =>
                      onChangeFile(e, "/api/images/upload")
                    }
                  // onChange={handleFileChange}
                  />
                </Button>

                {/* Display the selected image's name */}
                {document && (
                  <Typography variant="body2" marginTop={1}>
                    Selected Document: {document}
                    <span
                      className="remove"
                      onClick={() => removeImg(document)}
                    >
                      <IoCloseSharp />
                    </span>
                  </Typography>
                )}
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  startIcon={loading ? <CircularProgress size={20} /> : null}
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? "Saving..." : "Save Terms and Conditions"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateTermsAndConditionsPage;
