import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import image from "../../../assets/images/our-process/honey-1.jpg";

const OurProcess = () => {
  const theme = useTheme();
  const isLgDevice = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className="container">
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontSize: "1.75rem",
          fontWeight: "600",
          color: "var(--textColor)",
        }}
      >
        Our Process
      </Typography>
      <Typography variant="body1" paragraph align="center">
        Along with our scientific beekeeping process, we follow beehive to
        bottle policy. It makes sure you receive the best possible natural
        honey. Every time.
      </Typography>

      <Grid
        container
        spacing={isLgDevice ? 2 : 4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        {/* Step 1 */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Card
              elevation={2}
              sx={{
                borderRadius: "0.5rem",
                boxShadow: 2,
                border: "1px solid var(--borderColor)",
                background: "var(--bgLight)",
                height: {
                  xs: "auto",
                  sm: "100%",
                  md: "400px",
                  lg: "300px",
                  xl: "230px",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: "var(--textColor)",
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                  }}
                >
                  1. Selecting the Perfect Location
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "1.5rem",
                    textAlign: "justify",
                  }}
                >
                  We begin by carefully choosing the best environment for our
                  bees to thrive. The location is crucial as it influences the
                  quality of nectar the bees collect. Our chosen sites ensure
                  the production of the finest mono-flora honey, known for its
                  unique flavors and high demand.
                </Typography>
              </CardContent>
            </Card>

            <Card
              elevation={2}
              sx={{
                borderRadius: "0.5rem",
                boxShadow: 2,
                border: "1px solid var(--borderColor)",
                background: "var(--bgLight)",
                height: {
                  xs: "auto",
                  sm: "100%",
                  md: "400px",
                  lg: "300px",
                  xl: "230px",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: "var(--textColor)",
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                  }}
                >
                  2. Natural Beekeeping Practices
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "1.5rem",
                    textAlign: "justify",
                  }}
                >
                  Our beekeeping process is entirely natural. From hive to
                  bottle, we avoid heating, processing, or additives. This
                  ensures that the raw honey retains all its natural properties,
                  delivering the purest, most wholesome honey possible.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Image*/}
        <Grid item xs={12} md={4}>
          <Card
            elevation={2}
            sx={{
              borderRadius: "0.75rem",
              boxShadow: 2,
            }}
          >
            <CardMedia
              component="img"
              height="100%"
              image={image}
              alt="Beekeeper setting up colonies"
            />
          </Card>
        </Grid>

        {/* Step 2 */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Card
              elevation={2}
              sx={{
                borderRadius: "0.5rem",
                boxShadow: 2,
                border: "1px solid var(--borderColor)",
                background: "var(--bgLight)",
                height: {
                  xs: "auto",
                  sm: "100%",
                  md: "400px",
                  lg: "300px",
                  xl: "230px",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: "var(--textColor)",
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                  }}
                >
                  3. Harvesting with Care
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "1.5rem",
                    textAlign: "justify",
                  }}
                >
                  Once the bees have filled the honeycomb with nectar, we
                  carefully harvest the honey. We use gravity-based filtering
                  methods to remove any impurities, ensuring that the honey
                  remains untouched by unnecessary processing. This preserves
                  the flavor and nutrients of the honey.
                </Typography>
              </CardContent>
            </Card>

            <Card
              elevation={2}
              sx={{
                borderRadius: "0.5rem",
                boxShadow: 2,
                border: "1px solid var(--borderColor)",
                background: "var(--bgLight)",
                height: {
                  xs: "auto",
                  sm: "100%",
                  md: "400px",
                  lg: "300px",
                  xl: "230px",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: "var(--textColor)",
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                  }}
                >
                  4. Sealing the HoneyVeda Promise
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "1.5rem",
                    textAlign: "justify",
                  }}
                >
                  Our final step is ensuring that the honey is packed with care
                  and sealed with the HoneyVeda promise. Each jar is filled with
                  pure, natural honey, just as nature intended, guaranteeing you
                  the highest quality product every time.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurProcess;
