import React, { useState } from 'react';
import { postData } from '../../utils/api';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Snackbar,
    Alert,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

const WarehouseCreation = () => {
    const [result, setResult] = useState(null);
    const [sameAsPrimary, setSameAsPrimary] = useState(true);
    const [warehouseDetails, setWarehouseDetails] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        pin: '',
        return_address: '',
        return_pin: '',
        return_city: '',
        return_state: '',
        return_country: '',
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWarehouseDetails({ ...warehouseDetails, [name]: value });

        // If sameAsPrimary is checked, sync the return address fields
        if (sameAsPrimary && ['address', 'city', 'country', 'pin'].includes(name)) {
            setWarehouseDetails((prevState) => ({
                ...prevState,
                [`return_${name}`]: value,
            }));
        }
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSameAsPrimary(isChecked);

        if (isChecked) {
            setWarehouseDetails((prevState) => ({
                ...prevState,
                return_address: prevState.address,
                return_city: prevState.city,
                return_country: prevState.country,
                return_pin: prevState.pin,
                return_state: '', // Set to blank if no data available
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors

        try {
            const res = await postData(`/api/delhivery/create-warehouse`, warehouseDetails);
            setResult(res);
            setOpenSnackbar(true);
        } catch (err) {
            setError('Failed to create warehouse. Please try again.');
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Create Delhivery Warehouse
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    {/* Primary Address Fields */}
                    <Grid item xs={12}>
                        <TextField
                            label="Warehouse Name"
                            name="name"
                            value={warehouseDetails.name}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            type="email"
                            value={warehouseDetails.email}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Phone"
                            name="phone"
                            value={warehouseDetails.phone}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address"
                            name="address"
                            value={warehouseDetails.address}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={warehouseDetails.city}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Pin Code"
                            name="pin"
                            value={warehouseDetails.pin}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Country"
                            name="country"
                            value={warehouseDetails.country}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    {/* Checkbox for Same as Primary Address */}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sameAsPrimary}
                                    onChange={handleCheckboxChange}
                                    color="primary"
                                />
                            }
                            label="Return address is the same as the primary address"
                        />
                    </Grid>

                    {/* Return Address Fields */}
                    {!sameAsPrimary && (
                        <>
                            <Typography variant="h6" align="left" gutterBottom>
                                Return Address
                            </Typography>
                            <Grid item xs={12}>
                                <TextField
                                    label="Return Address"
                                    name="return_address"
                                    value={warehouseDetails.return_address}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return City"
                                    name="return_city"
                                    value={warehouseDetails.return_city}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return State"
                                    name="return_state"
                                    value={warehouseDetails.return_state}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return Pin Code"
                                    name="return_pin"
                                    value={warehouseDetails.return_pin}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Return Country"
                                    name="return_country"
                                    value={warehouseDetails.return_country}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </>
                    )}

                    {/* Submit Button */}
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Create Warehouse
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Warehouse created successfully!
                </Alert>
            </Snackbar>
            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
        </Container>
    );
};

export default WarehouseCreation;