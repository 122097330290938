import { Link } from "react-router-dom";

import { GiHoneyJar } from "react-icons/gi";
import { CiDeliveryTruck } from "react-icons/ci";
import { CiDiscount1 } from "react-icons/ci";
import { IoPricetagOutline } from "react-icons/io5";

import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import "./style.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="topInfo row">
            <div className="col">
              <span>
                <GiHoneyJar />
              </span>
              <span className="ml-2">Fresh Honey Products</span>
            </div>

            <div className="col">
              <span>
                <CiDeliveryTruck />
              </span>
              <span className="ml-2">Free delivery over Rs. 500</span>
            </div>

            <div className="col">
              <span>
                <CiDiscount1 />
              </span>
              <span className="ml-2">Daily Mega Discounts</span>
            </div>

            <div className="col">
              <span>
                <IoPricetagOutline />
              </span>
              <span className="ml-2">Best price on the market</span>
            </div>
          </div>

          <div className="row mt-5 linksWrap">
            <div className="col">
              <h5>HONEY</h5>
              <ul>
                <li>
                  <Link to="#">Rosewood Honey</Link>
                </li>
                <li>
                  <Link to="#">Mustard Honey</Link>
                </li>
                <li>
                  <Link to="#">Berry Honey</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>DRY FRUITS</h5>
              <ul>
                <li>
                  <Link to="#">Honey Mix Dry Fruits</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>BEE POLLEN</h5>
              <ul>
                <li>
                  <Link to="#">Raw Bee Pollen</Link>
                </li>
                <li>
                  <Link to="#">Organic Bee Pollen</Link>
                </li>
                <li>
                  <Link to="#">Bee Pollen Capsules</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>ROYAL JELLY</h5>
              <ul>
                <li>
                  <Link to="#">Organic Royal Jelly</Link>
                </li>
                <li>
                  <Link to="#">Royal Jelly Capsules</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>BEE WAX</h5>
              <ul>
                <li>
                  <Link to="#">Bee Wax Bulk</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>PROPOLIS</h5>
              <ul>
                <li>
                  <Link to="#">Propolis Tincture</Link>
                </li>
                <li>
                  <Link to="#">Propolis Spray</Link>
                </li>
                <li>
                  <Link to="#">Propolis Capsules</Link>
                </li>
              </ul>
            </div>

            <div className="col">
              <h5>BEE VENOM</h5>
              <ul>
                <li>
                  <Link to="#">Organic Bee Venom</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="legal">
            <div
              style={{ display: "flex", alignItems: "flex-start", gap: "1rem" }}
            >
              <p className="mb-0">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>

              <p className="mb-0">
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </p>
            </div>

            <ul className="socials">
              <li className="list-inline-item">
                <Link to="#">
                  <FaFacebookF />
                </Link>
              </li>

              <li className="list-inline-item">
                <Link to="#">
                  <FaTwitter />
                </Link>
              </li>

              <li className="list-inline-item">
                <Link to="#">
                  <FaInstagram />
                </Link>
              </li>
            </ul>
          </div>

          <hr className="divider" />

          <div className="copyright">
            <p>
              Copyright 2024. All rights reserved |{" "}
              <a
                href="https://www.gitnexa.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developed & Designed by <span>GitNexa</span>
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
