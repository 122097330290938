import React from "react";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

import { AiOutlineLogout } from "react-icons/ai";
import { LuUser2 } from "react-icons/lu";
import { RxDashboard } from "react-icons/rx";
import { MdLockOutline } from "react-icons/md";

const AdminProfilePopper = ({
  anchorEl,
  open,
  handleClose,
  userName,
  userEmail,
  userImage,
  handleLogout,
}) => {
  const navigate = useNavigate();

  const truncate = (str, max) =>
    str.length > max ? str.slice(0, max) + "..." : str;


  return (
    <Popper
      id="profile-popper"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      disablePortal
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper sx={{ width: 300, mt: 1 }}>
          {/* Header Section */}
          <div
            style={{ display: "flex", alignItems: "center", padding: "10px" }}
          >
            <Avatar
              src={userImage}
              alt={userName || "User Profile"}
              sx={{
                marginRight: "10px",
                width: "35px",
                height: "35px",
                color: "white",
                background: "var(--primaryColor)",
              }}
            />
            <div style={{ flexGrow: 1 }}>
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", fontWeight: "500" }}
              >
                {truncate(userName, 20)}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: "12px" }}
              >
                {truncate(userEmail, 25)}
              </Typography>
            </div>

            <IconButton onClick={handleLogout} color="primary" size="medium">
              <AiOutlineLogout />
            </IconButton>
          </div>

          <Divider />

          {/* Options */}
          <List>
            <ListItem button onClick={() => navigate(`/admin/dashboard`)}>
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <RxDashboard />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItem>

            <ListItem button onClick={() => navigate("/admin/my-account")}>
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <LuUser2 />
              </ListItemIcon>
              <ListItemText
                primary="My Account"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItem>

            <ListItem button onClick={() => navigate("/admin/my-account")}>
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <MdLockOutline />
              </ListItemIcon>
              <ListItemText
                primary="Change Password"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItem>

            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: "35px" }}>
                <AiOutlineLogout />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{ fontSize: "14px" }}
              />
            </ListItem>
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default AdminProfilePopper;
