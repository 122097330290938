import React, { useState, useContext } from "react";
import { postData } from "../../utils/api";
import "./style.css";
import ContactPageHeader from "../ContactUsHeader/ContactUsHeader";
import Lottie from "lottie-react";
import ContactUsAnimation from "../../animations/lottie/contact-us.json";
import { useSnackbar } from "../../context/SnackbarProvider";

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";

const ContactPage = () => {
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    concern: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    postData("/api/contact", formData).then((res) => {
      try {
        if (res.success === true) {
          const msg = res.message || "Internal srever error!!"
          showSnackbar(msg, "success", "#aadbaa");
        } else {
          const msg = res.message || "Internal srever error!!"
          // showSnackbar(msg, "error", "#f1b9b9");
          setLoading(false);
        }
      } catch (error) {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <section className="container">
      <ContactPageHeader />

      <Divider
        sx={{
          height: "1.5px",
          background: "var(--borderCool)",
          opacity: "0.5",
          marginTop: "3rem",
        }}
      />

      <Box className="contact-form">
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6 col-md-12 left-section">
            <Box className="contact-animation">
              <figure>
                <Lottie loop={true} animationData={ContactUsAnimation} />
              </figure>
            </Box>
          </div>

          <div className="col-12 col-xl-6 col-lg-6 col-md-12 right-section">
            <Box sx={{ width: "100%" }}>
              <Box className="description">
                <Typography variant="h1" component="h1">
                  Contact Us
                </Typography>
                <Typography variant="body1" component="p">
                  Let's talk about your project. Send us a message and we’ll get
                  back to you within one business day.
                </Typography>
                <Divider />
              </Box>

              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      name="concern"
                      value={formData.concern}
                      onChange={handleChange}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      disabled={loading}
                      sx={{ mt: 2 }}
                    >
                      {loading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    </section>
  );
};

export default ContactPage;
