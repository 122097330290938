import React, { useState, useContext } from 'react';
import { fetchDataFromApi } from '../../utils/api';
import { MyContext } from "../../App";

const PincodeServiceability = () => {
    const [pincode, setPincode] = useState('');
    const [result, setResult] = useState(null);
    const context = useContext(MyContext);

    const handleCheckServiceability = async () => {
        await fetchDataFromApi(`/api/delhivery/pincode-serviceability/${pincode}`)
            .then((res) => {
                setResult(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <h2>Check Pincode Serviceability</h2>
            <input
                type="text"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
            />
            <button onClick={handleCheckServiceability}>Check</button>
            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
        </div>
    );
};

export default PincodeServiceability;
