import React, { useState } from 'react';
import {
    TextField,
    Button,
    Container,
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import { postData } from '../../utils/api'; // Ensure postData is correctly implemented

const CalculateShippingCost = () => {
    const [shipmentDetails, setShipmentDetails] = useState({
        md: 'E', // Billing Mode (Express/Surface)
        cgm: 0,  // Weight in grams
        o_pin: '',
        d_pin: '',
        ss: 'Delivered', // Shipment status
        cod: 0,
        pt: '', // Payment type
    });

    const [result, setResult] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setShipmentDetails({ ...shipmentDetails, [name]: value });
    };

    const handleCalculateCost = async () => {
        setError(''); // Clear previous errors

        try {
            const res = await postData(`/api/delhivery/calculate-shipping-cost`, shipmentDetails);
            setResult(res); // Set result directly from response
            setOpenSnackbar(true);
        } catch (err) {
            console.error('Error calculating shipping cost:', err);
            setError('Failed to calculate shipping cost. Please try again.');
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Calculate Shipping Cost
            </Typography>

            <Box component="form" sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    {/* Billing Mode */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Billing Mode</InputLabel>
                            <Select
                                name="md"
                                value={shipmentDetails.md}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="E">Express (E)</MenuItem>
                                <MenuItem value="S">Surface (S)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Weight */}
                    <Grid item xs={12}>
                        <TextField
                            label="Weight (grams)"
                            name="cgm"
                            type="number"
                            value={shipmentDetails.cgm}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    {/* Origin and Destination Pincode */}
                    <Grid item xs={6}>
                        <TextField
                            label="Origin Pincode"
                            name="o_pin"
                            type="number"
                            value={shipmentDetails.o_pin}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Destination Pincode"
                            name="d_pin"
                            type="number"
                            value={shipmentDetails.d_pin}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    {/* Shipment Status */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Shipment Status</InputLabel>
                            <Select
                                name="ss"
                                value={shipmentDetails.ss}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Delivered">Delivered</MenuItem>
                                <MenuItem value="RTO">RTO</MenuItem>
                                <MenuItem value="DTO">DTO</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Payment Mode */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Payment Mode</InputLabel>
                            <Select
                                name="pt"
                                value={shipmentDetails.pt}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Pre-paid">Pre-paid</MenuItem>
                                <MenuItem value="COD">COD</MenuItem>
                                <MenuItem value="Pickup">Pickup</MenuItem>
                                <MenuItem value="REPL">REPL</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* COD Amount (conditional) */}
                    {shipmentDetails.pt === 'COD' && (
                        <Grid item xs={12}>
                            <TextField
                                label="COD Amount"
                                name="cod"
                                type="number"
                                value={shipmentDetails.cod}
                                onChange={handleInputChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    )}

                    {/* Calculate Cost Button */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleCalculateCost}
                        >
                            Calculate Cost
                        </Button>
                    </Grid>

                    {/* Error Message */}
                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}

                    {/* Display Result */}
                    {result && (
                        <Grid item xs={12}>
                            <pre>{JSON.stringify(result, null, 2)}</pre>
                        </Grid>
                    )}
                </Grid>
            </Box>

            {/* Snackbar for Success Message */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Shipping cost calculated successfully!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CalculateShippingCost;
