import React, { useState } from 'react';
import { postData } from '../../utils/api'
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Snackbar,
    Alert,
} from '@mui/material';

const CreatePickupRequest = () => {
    const [pickupDetails, setPickupDetails] = useState({
        pickup_time: '',
        pickup_date: '',
        pickup_location: '',
        expected_package_count: '',
    });

    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const validateField = (name, value) => {
        if (name === 'pickup_time') {
            const timeMatch = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(value);
            if (!timeMatch && value !== '') {
                setError('Time must be in hh:mm:ss format');
                return false;
            }
        }

        if (name === 'pickup_date') {
            const dateMatch = /^\d{4}-\d{2}-\d{2}$/.test(value);
            if (!dateMatch && value !== '') {
                setError('Date must be in YYYY-MM-DD format');
                return false;
            }
        }

        setError('');
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPickupDetails({ ...pickupDetails, [name]: value });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleCreatePickupRequest = async () => {
        try {
            setError('');
            const res = await postData("/api/delhivery/create-pickup-request", pickupDetails);
            setResult(res);
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error creating pickup request:', error);
            setError('Failed to create pickup request. Please try again.');
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Create Pickup Request
            </Typography>
            {error && <Alert severity="error" sx={{ marginBottom: '16px' }}>{error}</Alert>}
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            label="Pickup Location"
                            name="pickup_location"
                            value={pickupDetails.pickup_location}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Pickup Date (YYYY-MM-DD)"
                            name="pickup_date"
                            placeholder="YYYY-MM-DD"
                            value={pickupDetails.pickup_date}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            label="Pickup Time (hh:mm:ss)"
                            name="pickup_time"
                            placeholder="hh:mm:ss"
                            value={pickupDetails.pickup_time}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Expected Package Count"
                            name="expected_package_count"
                            type="number"
                            value={pickupDetails.expected_package_count}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleCreatePickupRequest}
                        >
                            Create Pickup
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Pickup request created successfully!
                </Alert>
            </Snackbar>

            {result && (
                <pre style={{ marginTop: '16px', overflow: 'auto', backgroundColor: '#f9f9f9', padding: '16px' }}>
                    {JSON.stringify(result, null, 2)}
                </pre>
            )}
        </Container>
    );
};

export default CreatePickupRequest;