"use client";

import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Typography,
  Switch,
  TablePagination,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  deleteData,
  editData,
  fetchDataFromApi,
} from "../../../../../utils/api";
import { useSnackbar } from "../../../../../context/SnackbarProvider";
import {
  AiOutlineEye,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlus,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom"; // Import useNavigate

// Custom StyledTableCell and StyledTableRow
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--primaryColor)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const PrivacyPolicyList = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPolicyId, setSelectedPolicyId] = useState(null);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (!token || !user || user.role !== "admin") {
      showSnackbar("Forbidden: Insufficient Permissions.", "error", "#f1b9b9");
      navigate('/')
    }

    const fetchPrivacyPolicy = async () => {
      try {
        setLoading(true);
        const res = await fetchDataFromApi(`/api/privacy-policy`);
        if (res.success !== false) {
          setPrivacyPolicy(res);
          const msg = res.message || "Login successfully!!"
          // showSnackbar(msg, "success", "#aadbaa");
        } else {
          const msg = res.message || "Something went wrong!!"
          // showSnackbar(msg, "error", "#f1b9b9");
        }
      } catch (error) {
        const msg = error.message || "Internal srever error!!"
        // showSnackbar(msg, "error", "#f1b9b9");
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  const handleShowInHomePageChange = async (id, policy, newStatus) => {
    policy.activeStatus = newStatus;
    editData(`/api/privacy-policy/${id}`, policy).then((res) => {
      fetchDataFromApi(`/api/privacy-policy`).then((res) => {
        setPrivacyPolicy(res);
        showSnackbar("Satus Updated Successfully!!", "success", "#aadbaa");
      });
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedPolicyId(id);
    setOpenDeleteDialog(true);
  };

  const handleDelete = async () => {
    if (!selectedPolicyId) return;

    await deleteData(`/api/privacy-policy/${selectedPolicyId}`).then((res) => {
      setPrivacyPolicy(privacyPolicy.filter((policy) => policy._id !== selectedPolicyId));
      showSnackbar("Policy Deleted Successfully!!", "success", "#aadbaa");
    }).catch((error) => {
      const msg = error.message || "Internal srever error!!"
      // showSnackbar(msg, "error", "#f1b9b9");
    });

    setOpenDeleteDialog(false);
    setSelectedPolicyId(null);
  };

  const handleEdit = (privacyPolicy) => {
    if (privacyPolicy._id) {
      navigate(`/admin/legal/edit-privacy-policy/${privacyPolicy._id}`);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${d.getFullYear()}`;
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: "1rem",
            fontWeight: "600",
            color: "var(--textHeading)",
            marginBottom: "2rem",
          }}
        >
          Dashboard / Privacy Policy List
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AiOutlinePlus />}
          onClick={() => navigate("/admin/legal/add-privacy-policy")}
          sx={{ width: "230px", padding: "12px 16px" }}
        >
          Add New Privacy Policy
        </Button>
      </Box>

      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Card
          sx={{
            overflowX: "auto",
            borderRadius: 3,
            boxShadow: 2,
            border: "1px solid var(--borderCool)",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              boxShadow: 0,
              borderRadius: "0",
              maxHeight: "75vh",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <strong>Title</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Version</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Updated At</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Status</strong>
                  </StyledTableCell>
                  <StyledTableCell>
                    <strong>Action</strong>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {privacyPolicy.length > 0 ? (
                  privacyPolicy.map((policy) => (
                    <StyledTableRow key={policy._id}>
                      <StyledTableCell>{policy.title}</StyledTableCell>
                      <StyledTableCell>{policy.version}</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(policy.updatedAt)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Switch
                          checked={policy.activeStatus}
                          onChange={(e) =>
                            handleShowInHomePageChange(
                              policy._id,
                              policy,
                              e.target.checked
                            )
                          }
                          color="primary"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          minWidth: "100px",
                          display: "flex",
                          gap: "1rem",
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={() =>
                            navigate(
                              `/admin/legal/view-privacy-policy/${policy._id}`
                            )
                          }
                          sx={{
                            border: "1px solid var(--borderCool)",
                          }}
                        >
                          <AiOutlineEye style={{ fontSize: "1.15rem" }} />
                        </IconButton>

                        <IconButton
                          color="secondary"
                          onClick={() => handleEdit(policy)}
                          sx={{
                            border: "1px solid var(--borderCool)",
                          }}
                        >
                          <AiOutlineEdit style={{ fontSize: "1.15rem" }} />
                        </IconButton>

                        <IconButton
                          color="error"
                          onClick={() => handleDeleteConfirmation(policy._id)}
                          sx={{
                            border: "1px solid var(--borderCool)",
                          }}
                        >
                          <AiOutlineDelete style={{ fontSize: "1.15rem" }} />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <Typography>
                        <strong>No any privacy policy available.</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* TablePagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={privacyPolicy.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      )}

      {/* Delete confirmation dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Privacy Policy</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this privacy policy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PrivacyPolicyList;
