import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import image from "../../../assets/images/our-process/honey-2.jpg";

const promises = [
  {
    title: "Pure and natural",
    description:
      "We don’t process or pasteurize our honey. What you get is exactly what nature intended – pure, raw honey straight from the hive. Shopping with us means you’re getting the best quality possible.",
  },
  {
    title: "Fresh from the hive",
    description:
      "Our honey comes straight from the hive without any processing or artificial flavoring. It’s just pure, natural honey, whether you buy it online or in-store. You get all the benefits that real honey offers.",
  },
  {
    title: "A smooth, rich taste",
    description:
      "When you drizzle our honey, you can almost picture the farms and flowers where it comes from. It’s a thick, smooth honey with a rich taste that brings nature right to your table.",
  },
  {
    title: "Experts in honey",
    description:
      "We focus only on honey, and that means we know our stuff. With our expertise in beekeeping and honey harvesting, you can trust that every jar is full of pure, raw honey, just as promised.",
  },
];

function OurPromises() {
  return (
    <Box component="section" className="container" sx={{ marginTop: "70px" }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{
          fontSize: "1.75rem",
          fontWeight: "600",
          color: "var(--textColor)",
        }}
      >
        Our Promises
      </Typography>
      <Typography variant="body1" paragraph align="center">
        At Royal BeeKeeper, we believe in delivering honey that stays true to
        nature. Here are the key promises that set our honey apart.
      </Typography>

      <Grid
        container
        spacing={4}
        sx={{
          marginTop: "2rem",
          xl: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            order: { xs: 2, md: 1 },
          }}
        >
          <Grid container spacing={2}>
            {promises.map((promise, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <Card
                  sx={{
                    p: 2,
                    borderRadius: "0.5rem",
                    border: "1px solid var(--borderCool)",
                    boxShadow: 2,
                    height: "100%",
                    minHeight: "200px",
                    background: "var(--bgLight)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "var(--textColor)",
                      fontWeight: "500",
                      fontSize: "1.25rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {promise.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.9rem",
                      lineHeight: "1.5rem",
                      textAlign: "justify",
                    }}
                  >
                    {promise.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            order: { xs: 1, md: 2 },
          }}
        >
          <Box
            component="img"
            src={image}
            alt="Royal BeeKeeper Honey"
            sx={{
              width: "100%",
              borderRadius: "0.75rem",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OurPromises;
