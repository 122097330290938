import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CountryDropdown from "../CountryDropdown";
import { FiUser } from "react-icons/fi";
import { IoBagOutline } from "react-icons/io5";
import SearchBox from "./SearchBox";
import Navigation from "./Navigation";
import { fetchDataFromApi } from "../../utils/api";
import { useSnackbar } from "../../context/SnackbarProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FaClipboardCheck } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { RiLogoutCircleRFill } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import axios from "axios";

import "./style.css";
import { Co2Sharp } from "@mui/icons-material";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [cartData, setCartData] = useState();
  const [adminOrder, setAdminOrder] = useState();
  const [countryList, setCountryList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setsubCategoryData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const open = Boolean(anchorEl);

  const headerRef = useRef();
  const { showSnackbar } = useSnackbar();
  const history = useNavigate();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("location");
    history("/signIn");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user?.userId !== "" &&
      user?.userId !== undefined &&
      user?.userId !== null
    ) {
      fetchDataFromApi(`/api/cart?userId=${user?.userId}`).then((res) => {
        setCartData(res);
      });
    }

    fetchDataFromApi(`/api/orders/`).then((res) => {
      setAdminOrder(res);
    });

    getCountry("https://countriesnow.space/api/v0.1/countries/");

    fetchDataFromApi("/api/category")
      .then((res) => {
        setCategoryData(res.categoryList);
        const subCatArr = [];

        res.categoryList?.length !== 0 &&
          res.categoryList?.map((cat, index) => {
            if (cat?.children.length !== 0) {
              cat?.children?.map((subCat) => {
                subCatArr.push(subCat);
              });
            }
          });

        setsubCategoryData(subCatArr);
      })
      .catch((error) => {
        const msg = error.message || "Internal srever error!!";
        // showSnackbar(msg, "error", "#f1b9b9");
      });

    window.addEventListener("scroll", () => {
      let position = window.pageYOffset;
      if (headerRef.current) {
        if (position > 100) {
          headerRef.current.classList.add("fixed");
        } else {
          headerRef.current.classList.remove("fixed");
        }
      }
    });
  }, []);

  const getCountry = async (url) => {
    await axios.get(url).then((res) => {
      setCountryList(res.data.data);

    });
  };

  const openNav = () => {
    setIsOpenNav(!isOpenNav);
  };

  const closeNav = () => {
    setIsOpenNav(false);
  };

  const openSearch = () => {
    setIsOpenSearch(!isOpenSearch);
  };

  const closeSearch = () => {
    setIsOpenSearch(false);
  };

  const jsonUser = localStorage.getItem("user");
  const user = JSON.parse(jsonUser);
  console.log("User : ", user)

  const renderAvatar = (avatar) =>
    avatar ? (
      <Avatar src={avatar} alt="Profile" sx={{
        width: { xs: 35, sm: 35, md: 45, lg: 45 },
        height: { xs: 35, sm: 35, md: 45, lg: 45 },
      }} />
    ) : (
      <FiUser />
    );

  return (
    <>
      <div className="headerWrapperFixed" ref={headerRef}>
        <div className="headerWrapper">
          <header className="header">
            <div className="container">
              <div className="row">
                <div className="logoWrapper d-flex align-items-center col-sm-2">
                  <Link to={"/"} className="nav-logo">
                    <img src={Logo} alt="Logo" />
                    <small>Royal BeeKeeper</small>
                  </Link>
                </div>

                <div className="col-sm-10 d-flex align-items-center part2">
                  {countryList.length !== 0 && windowWidth > 992 && (
                    <CountryDropdown allCountry={countryList} />
                  )}

                  <div
                    className={`headerSearchWrapper ${isOpenSearch === true && "open"
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="closeSearch mr-3"
                        onClick={() => setIsOpenSearch(false)}
                      >
                        <FaAngleLeft />
                      </span>
                      <SearchBox closeSearch={closeSearch} />
                    </div>
                  </div>

                  <div className="part3 d-flex align-items-center ml-auto">
                    {windowWidth < 992 && (
                      <Button
                        className="circle toggleNav"
                        onClick={openSearch}
                        sx={{ mr: { xs: 2, md: 3 } }}
                      >
                        <IoIosSearch />
                      </Button>
                    )}

                    {user ? (
                      user?.role === "admin" ? (
                        <Button
                          className="circle"
                          onClick={() => history("/admin/dashboard")}
                        >
                          {renderAvatar(user.avatar)}
                        </Button>
                      ) : (
                        <>
                          <Button className="circle" onClick={handleClick}>
                            {renderAvatar(user.avatar)}
                            {/* {user.avatar ? (
                              <Avatar
                                src={user.avatar}
                                alt="Profile"
                                sx={{
                                  width: { xs: 35, sm: 35, md: 45, lg: 45 },
                                  height: { xs: 35, sm: 35, md: 45, lg: 45 },
                                }}
                              />
                            ) : (
                              <FiUser />
                            )} */}
                          </Button>
                          <Menu
                            anchorEl={anchorEl}
                            id="accDrop"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <Link to="/my-account">
                              <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                  <FaUserAlt fontSize="small" />
                                </ListItemIcon>
                                My Account
                              </MenuItem>
                            </Link>
                            <Link to="/orders">
                              <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                  <FaClipboardCheck fontSize="small" />
                                </ListItemIcon>
                                Orders
                              </MenuItem>
                            </Link>
                            <Link to="/my-list">
                              <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                  <FaHeart fontSize="small" />
                                </ListItemIcon>
                                My List
                              </MenuItem>
                            </Link>
                            <MenuItem onClick={logout}>
                              <ListItemIcon>
                                <RiLogoutCircleRFill fontSize="small" />
                              </ListItemIcon>
                              Logout
                            </MenuItem>
                          </Menu>
                        </>
                      )
                    ) : (
                      <li className="list-inline-item pl-3">
                        <Link
                          to="/signIn"
                          style={{
                            color: "var(--textColor)",
                            fontWeight: "600",
                            textTransform: "uppercase",
                            fontSize: "14px",
                          }}
                        >
                          Login
                        </Link>
                      </li>
                    )}

                    {
                      user && user.role === "admin" ? (
                        < div className="cartTab d-flex align-items-center">
                          <Box
                            className="position-relative"
                            sx={{ ml: { xs: 2, md: 3 } }}
                          >
                            <Link to="/admin/orders">
                              <Button className="circle">
                                <IoBagOutline />
                              </Button>
                              <span className="count d-flex align-items-center justify-content-center">
                                {adminOrder?.length > 0 ? adminOrder?.length : 0}
                              </span>
                            </Link>
                          </Box>

                          <Box
                            className="position-relative"
                            sx={{ ml: { xs: 2, md: 3 } }}
                          >
                            <Button className="circle" onClick={logout}>
                              <RiLogoutCircleRFill />
                            </Button>
                          </Box>

                          {windowWidth < 992 && (
                            <Button
                              className="circle"
                              onClick={openNav}
                              sx={{ ml: { xs: 2, md: 3 } }}
                            >
                              <IoMdMenu />
                            </Button>
                          )}
                        </div>
                      ) : (
                        < div className="cartTab d-flex align-items-center">
                          {windowWidth > 992 && (
                            <span className="price" style={{ marginLeft: "24px" }}>
                              {(cartData?.length !== 0
                                ? cartData
                                  ?.map(
                                    (item) => parseInt(item.price) * item.quantity
                                  )
                                  .reduce((total, value) => total + value, 0)
                                : 0
                              )?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "INR",
                              })}
                            </span>
                          )}

                          <Box
                            className="position-relative"
                            sx={{ ml: { xs: 2, md: 3 } }}
                          >
                            <Link to="/cart">
                              <Button className="circle">
                                <IoBagOutline />
                              </Button>
                              <span className="count d-flex align-items-center justify-content-center">
                                {cartData?.length > 0 ? cartData?.length : 0}
                              </span>
                            </Link>
                          </Box>
                          {windowWidth < 992 && (
                            <Button
                              className="circle"
                              onClick={openNav}
                              sx={{ ml: { xs: 2, md: 3 } }}
                            >
                              <IoMdMenu />
                            </Button>
                          )}
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </header>

          {categoryData?.length !== 0 && (
            <Navigation
              navData={categoryData}
              isOpenNav={isOpenNav}
              closeNav={closeNav}
            />
          )}
        </div >
      </div >
    </>
  );
};

export default Header;
